import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
// import Linphone from '../pages/Linphone';

// import ChatBot from "react-simple-chatbot";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/SkyLab.css";
const SkyLab = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);

  //Can un-comment when using chatbot
  // Chatbot Code
  // const steps = [
  //   {
  //     id: "1",
  //     message: "What is your name?",
  //     trigger: "2",
  //   },
  //   {
  //     id: "2",
  //     user: true,
  //     trigger: "3",
  //   },
  //   {
  //     id: "3",
  //     message: "Hi {previousValue}, Welcome to Sky Lab!",
  //     trigger: "4",
  //   },
  //   {
  //     id: "4",
  //     message: "Level 2 verification is required to proceed beyond this point.",
  //     trigger: "5",
  //   },
  //   {
  //     id: "5",
  //     message: "Please complete the following biometric authentication.",
  //     trigger: "6",
  //   },
  //   {
  //     id: "6",
  //     message: "Thank You for using me! We are currently in development phase.",
  //     end: true,
  //   },
  // ];
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul
            className="navbar-nav d-flex justify-content-between align-items-center w-100"
            style={{ fontSize: "1.1rem" }}
          >
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/skyLab">
                Satellite Constellations
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/skyLab">
                Network Optimization
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/skyLab">
                Projects
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/skyLab">
                Team
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <Link
                className="text-white"
                to="/art/aiLabsList/skyLab/phoenix-uplink"
              >
                Phoenix Uplink
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* <Linphone isLinphoneOpened={isLinphoneOpened} setIsLinphoneOpened={setIsLinphoneOpened} /> */}

      <div ref={contentRef} className="skyLab">
        <div className="header">
          <section className=" w-100 d-flex align-items-center">
            <h1 className="w-75 m-auto text-center shadow">Sky Lab</h1>
          </section>
        </div>
        <div className="card m-5 p-4 shadow">
          <h4 className="text-center">
            Satellite/Network Connectivity Research and Development
          </h4>
          <p className="text-justify">
            <br />
            At Sky Lab, we are reaching for the heavens - quite literally. Our
            mission is to revolutionize satellite and network connectivity
            through AI-driven research and development. By pushing the
            boundaries of communication technology, we aim to create a world
            where seamless connectivity transcends barriers, empowering
            communities worldwide.
            <br />
            <br />
            Through strategic partnerships and groundbreaking projects, Sky Lab
            is paving the way for a new era of communication, where every corner
            of the globe is within reach. From satellite constellations to
            network optimization, we are engineering a connected future that
            knows no limits.
          </p>
          <video controls width="100%" height="440px">
            <source
              src="https://gateway.pinata.cloud/ipfs/QmQtFNZPdqJmv5dgx2bP4xvX39KDLZvGT9CYaUBBzwVvp7"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="card m-5 p-4 shadow">
          <h4 className="text-center">Projects</h4>
          <p className="text-justify">
            <br />
            Explore the cutting-edge projects at Sky Lab that redefine the
            future of satellite and network connectivity. From next-gen
            communication satellites to AI-optimized network protocols, our
            projects push the boundaries of what is possible in the realm of
            space technology.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4 shadow">
          <h4 className="text-center">Research</h4>
          <p className="text-justify">
            <br />
            Dive deep into the heart of our research initiatives. Learn how AI
            is transforming satellite communication efficiency, network
            security, and global connectivity. Sky Lab is where groundbreaking
            ideas take flight, connecting the world in ways never imagined.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4 shadow">
          <h4 className="text-center">Team</h4>
          <p className="text-justify">
            <br />
            Meet the brilliant minds behind Sky Lab. Our diverse team of
            engineers, data scientists, and space enthusiasts work tirelessly to
            bring you the future of satellite and network connectivity.
            Together, we strive to make the world a smaller, more connected
            place.
            <br />
          </p>
        </div>
      </div>
      <div>
        {/* <ThemeProvider theme={theme}> */}
        {/* <ChatBot steps={steps} floating={true} /> */}
        {/* </ThemeProvider> */}
      </div>
    </>
  );
};

export default SkyLab;
