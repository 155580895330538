import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
// import ChatBot from "react-simple-chatbot";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/XLab.css";
const XLab = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  // Chatbot Code
  // const steps = [
  //   {
  //     id: "1",
  //     message: "What is your name?",
  //     trigger: "2",
  //   },
  //   {
  //     id: "2",
  //     user: true,
  //     trigger: "3",
  //   },
  //   {
  //     id: "3",
  //     message: "Hi {previousValue}, Welcome to X Lab!",
  //     trigger: "4",
  //   },
  //   {
  //     id: "4",
  //     message: "Level 3 verification is required to proceed beyond this point.",
  //     trigger: "5",
  //   },
  //   {
  //     id: "5",
  //     message: "Please complete the following biometric authentication.",
  //     trigger: "6",
  //   },
  //   {
  //     id: "6",
  //     message: "Thank You for using me! We are currently in development phase.",
  //     end: true,
  //   },
  // ];
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul
            className="navbar-nav d-flex justify-content-between align-items-center w-100 "
            style={{ fontSize: "1.1rem" }}
          >
            <li className="nav-item px-5 py-2 p-md-0 mx-1 text-center">
              <a className="text-white " href="/art/aiLabsList/xLab">
                Autonomous Systems
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 mx-1 text-center">
              <a className="text-white " href="/art/aiLabsList/xLab">
                Advanced Robotics
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 mx-1 text-center">
              <a className="text-white " href="/art/aiLabsList/xLab">
                Security Solutions
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 mx-3 text-center">
              <a className="text-white " href="/art/aiLabsList/xLab">
                Innovations
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 mx-3 text-center">
              <a className="text-white " href="/art/aiLabsList/xLab">
                Connect
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 mx-3 text-center">
              <a className="text-white " href="/art/aiLabsList/xLab">
                Events
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 mx-3 text-center">
              <a className="text-white " href="/art/aiLabsList/xLab">
                Blogs
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div ref={contentRef} className="xLab">
        <div className="header">
          <section className=" w-100 d-flex align-items-center">
            <h1 className="w-75 m-auto text-center shadow">X Lab</h1>
          </section>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center">
            Special Operations Research and Development
          </h4>
          <p className="text-justify">
            <br />
            X Lab represents the cutting edge of AI research with a focus on
            special operations. Here, we explore applications of AI that
            transcend conventional boundaries, revolutionizing fields such as
            defense, security, and beyond. Our audacious projects redefine the
            art of the possible and set new standards for technological
            innovation.
            <br />
            <br />
            With a team composed of experts from diverse backgrounds, X Lab
            tackles complex challenges head-on. From autonomous systems to
            advanced robotics, our research lays the groundwork for a safer,
            more secure future, where AI becomes the cornerstone of progress.
          </p>
          <video controls width="100%" height="440px">
            <source
              src="https://gateway.pinata.cloud/ipfs/QmVxBC4V4Dm9T5upMBFNT8qLY1JpoAd26JSVYxzbyht3Je"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center">Innovations</h4>
          <p className="text-justify">
            <br />
            Discover the latest innovations in AI technology developed at X-Lab.
            From AI-enhanced security systems to autonomous drones, our
            innovations redefine what is possible in the realm of special
            operations.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center">Events</h4>
          <p className="text-justify">
            <br />
            Stay tuned for events that showcase the intersection of AI and
            special operations. From live demonstrations to expert panels, X-Lab
            events provide a glimpse into the future of defense and security.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center">Blog</h4>
          <p className="text-justify">
            <br />
            Read our blog for in-depth insights into the world of special
            operations AI. Explore thought-provoking articles, case studies, and
            expert opinions that highlight the transformative power of AI in
            high-stakes environments.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center">Connect</h4>
          <p className="text-justify">
            <br />
            Connect with X-Lab and be part of a network of professionals,
            enthusiasts, and experts in the field of special operations AI.
            Collaborate, share ideas, and be at the forefront of shaping the
            future of defense technology.
            <br />
          </p>
        </div>
      </div>
      <div>
        {/* <ThemeProvider theme={theme}> */}
        {/* <ChatBot steps={steps} floating={true} /> */}
        {/* </ThemeProvider> */}
      </div>
    </>
  );
};

export default XLab;
