import React, { useState } from "react";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import VoiceRecognition from "./VoiceRecognition";
import "../../styles/Transcribe.css";

const Transcribe = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([{ name: null }]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [transcribedText, setTranscribedText] = useState("");

  const handleChange = async (e) => {
    setIsLoading(true);
    const tempFile = [];
    const audioFile = e.target.files[0];
    await tempFile.push(audioFile);
    await setFile(tempFile);
    setIsLoading(false);
    setIsFileUploaded(true);
  };

  const handleSubmit = async () => {
    try {
      if (file[0].name) {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", file[0]);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await axios.post(
          "http://127.0.0.1:8000/transcript-v2/",
          formData,
          config
        );
        const transcribedText = response.data;
        setTranscribedText(transcribedText);
        console.log(transcribedText);
      } else {
        console.error("No file selected");
      }
    } catch (error) {
      console.error("Error while sending the audio file", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Transcribe d-flex flex-column align-items-center justify-content-center">
      <div className="Layout h-100 w-100 d-flex align-items-center justify-content-center">
        <div className="UpperCard rounded d-flex justify-content-center align-items-center">
          <div className="FormCard text-center d-flex flex-column justify-content-evenly align-items-center">
            <h1>Transcribe</h1>
            <p>Upload audio files using the button below to get started</p>
            <VoiceRecognition />
            <input
              type="file"
              name="file"
              id="file"
              accept="audio/*"
              onChange={handleChange}
            />
            <label htmlFor="file" className="btn btn-primary w-100">
              <i className="bi bi-upload"></i> Upload Audio
            </label>
            {isLoading && <LinearProgress style={{ width: "100%" }} />}
            {file[0].name === null ? (
              <div>No File Selected</div>
            ) : (
              <div>Selected {file[0].name}</div>
            )}
            {isFileUploaded && (
              <button
                className="btn btn-primary btn-sm bx-5"
                onClick={handleSubmit}
              >
                Get Started
              </button>
            )}
          </div>
          <div style={{ background: "black", color: "white" }}>
            {transcribedText.recognized_text}
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Transcribe;