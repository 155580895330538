import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Wallet.css";
const Wallets = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white"
                aria-current="page"
                href="/cryptoTechnologyCenter/"
              >
                Home
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/cryptoTechnologyCenter/events">
                Events & Webinars
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/membership">
                Membership
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Division <ArrowDropDownIcon />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/researchDevelopment"
                  >
                    Research & Development
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps"
                  >
                    DApp's Showcase
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/educationalHub"
                  >
                    Educational Hub
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dao"
                  >
                    DAO
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptoProducts"
                  >
                    Crypto Products/Services
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/communityForum"
                  >
                    Community Forum
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptotools"
                  >
                    Crypto Currency Tools
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="Wallet">
        <div className="Header">
          <section>
            <h1 className="Heading">Wallet Technologies</h1>
          </section>
        </div>
        <div className="Content">
          <section className="card p-4 shadow-lg m-2">
            <h3>Introduction to Various Wallet Types</h3>
            <p>
              Navigate the world of wallets with confidence through our
              comprehensive guide. Explore the different types of wallets and
              choose the one that aligns with your needs and preferences.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Wallet Security Best Practices</h3>
            <p>
              Prioritize the security of your digital assets with our best
              practices guide. Learn how to safeguard your wallets and adopt
              security measures that ensure the integrity of your crypto
              holdings.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Integration of Wallets with Phoenix Labs Products/Services</h3>
            <p>
              Unlock the full potential of your crypto experience by seamlessly
              integrating wallets with Phoenix Labs products and services.
              Experience a cohesive ecosystem that enhances usability and
              functionality.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default Wallets;
