import React, { useState } from "react";
import axios from "axios";
import "../../styles/Login.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const payload = {
        email: email,
        password: password,
      };
      await axios
        .post("https://phoenix-labs.onrender.com/api/v1/auth/login", payload)
        // .post("https://phoenix-labs.onrender.com/api/v1/auth/login", payload)
        .then((response) => {
          setLoading(false);
          setErrorMessage(response.data.msg);
          localStorage.setItem("user_phoenixlab_user", response.data.token);
          navigate("/art");
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(error.response.data.msg);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  return (
    <div className="Login d-flex justify-content-center align-items-center">
      <div className="Layout">
        <p className="fs-1 m-0 text-center">Welcome</p>
        <p className="fs-5 mb-4 text-center">
          Please login to access our platform
        </p>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column justify-content-center align-items-center gap-2"
        >
          <input
            className="w-100 p-2 ps-3"
            type="email"
            autoComplete="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="w-100 position-relative">
            {showPassword ? (
              <i
                className="bi bi-eye-slash-fill position-absolute  "
                onClick={() => setShowPassword(false)}
              ></i>
            ) : (
              <i
                className="bi bi-eye-fill position-absolute  "
                onClick={() => setShowPassword(true)}
              ></i>
            )}
            <input
              className="w-100 p-2 ps-3 "
              type={`${showPassword ? "text" : "password"}`}
              autoComplete="current-password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
              min={8}
            />
          </div>
          {errorMessage && (
            <div className="text-center text-danger w-100 shakeError">
              {errorMessage}
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                className=" w-100 text-start text-center"
                style={{ fontSize: "0.8rem" }}
              >
                <span>Don't have a account? </span>
                <a href="/register">Register..</a>
              </div>
              <button
                type="submit "
                className="btn btn-secondary btn-sm mt-2 ps-3 pe-3"
              >
                Login
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
