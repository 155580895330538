import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/Data_collection.css";
import PayPal from "./PayPal";
const Data_collection = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);
  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white fs-5"
                aria-current="page"
                href="#imageCollection"
              >
                Image Collection
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="#preLabelled">
                Pre-Labeled Collection
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="#pointOfInterest">
                Point of Interest
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="#syntheticData">
                Synthetic Data
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div ref={contentRef} className="dataCollection">
        <div className="Header d-flex justify-content-center align-items-center">
          <h1 className="w-75 m-auto text-center">Data Collection</h1>
        </div>
        <main className="p-4">
          <div className="m-4 p-3 align-items-center" id="imageCollection">
            <h1 className="">Image Collection</h1>
            <p className="m-0">
              Welcome to Phoenix Labs' Image Collection! We provide a
              comprehensive range of high-quality images for individuals,
              businesses, and organizations within our network. Our image
              collection is diverse and caters to various industries and use
              cases. Whether you need images for personal projects, marketing
              campaigns, research purposes, or any other application, we have
              you covered.
              <span className="d-block mt-2"></span>
              Our Data Collection Archives offer a vast selection of images
              sourced ethically from a variety of environments, including homes,
              studios, offices, vehicles, and public spaces. With a focus on
              quality and relevance, our curated image datasets ensure that you
              can find the perfect visuals to enhance your work.
              <span className="d-block mt-2"></span>
              By leveraging our State-Of-The-Art Digitally Managed Data
              Dispensary, accessing and downloading the images you need is
              seamless and efficient. Our user-friendly interface allows you to
              search, filter, and preview images, ensuring a smooth experience
              throughout the entire process. You can trust that our datasets are
              up-to-date, reliable, and compatible with any platform or
              application you are using.
              <span className="d-block mt-2"></span>
              Discover the possibilities of our Image Collection and unlock the
              power of visual data for your projects. From stunning landscapes
              to professional-grade product shots, our collection has it all.
              Join our network today and gain access to an extensive range of
              ethically sourced images that will take your creativity to new
              heights.
            </p>
          </div>
          <div className="m-4 p-3" id="preLabelled">
            <h1 className="mt-4">Pre-Labeled Collection</h1>
            <p className="text-justify">
              Welcome to Phoenix Labs' Pre-Labeled Collection! We understand
              that labeling and categorizing data can be a time-consuming and
              challenging task. That's why we offer a specialized dataset
              designed to streamline your workflow and save you valuable time
              and resources.
              <span className="d-block mt-2"></span>
              Our Pre-Labeled Collection provides meticulously annotated data
              across various data types, including speech, text, image, and
              video. Each dataset is carefully labeled by our team of experts,
              ensuring accuracy and consistency. Whether you are training
              machine learning models, conducting research, or developing
              innovative applications, our pre-labeled datasets can
              significantly accelerate your progress.
              <span className="d-block mt-2"></span>
              By utilizing our Data Collection Archives, you gain access to a
              wide range of pre-labeled data that fits any use case. Our diverse
              collection covers multiple domains, allowing you to find datasets
              relevant to your specific needs. We prioritize ethical sourcing
              and adhere to strict quality standards, ensuring that our
              pre-labeled datasets provide reliable foundations for your
              projects. <br />
              <span className="d-block mt-2"></span>
              Accessing our Pre-Labeled Collection is simple and convenient
              through our State-Of-The-Art Digitally Managed Data Dispensary.
              Our intuitive interface allows you to browse, search, and download
              the labeled datasets you require with ease. Empower your AI
              systems, improve accuracy, and reduce development time with our
              comprehensive pre-labeled datasets.
              <span className="d-block mt-2"></span>
              Unlock the potential of pre-labeled data and revolutionize your
              projects with Phoenix Labs. Join our network today and discover
              how our Pre-Labeled Collection can supercharge your data-driven
              endeavors.
            </p>
          </div>
          <div className="m-4 p-3" id="pointOfInterest">
            <h1 className="mt-4">Point Of Interest</h1>
            <p className="text-justify">
              Welcome to Phoenix Labs' Point Of Interest page! In our vast Data
              Collection Archives, we offer a specialized dataset that focuses
              on capturing points of interest across various environments.
              Whether you need data for navigation systems, location-based
              services, augmented reality applications, or urban planning, our
              Point Of Interest collection provides the information you need.
              <span className="d-block mt-2"></span>
              Our dataset encompasses detailed information about points of
              interest in homes, studios, offices, vehicles, and public spaces.
              From restaurants and landmarks to businesses and cultural
              attractions, our curated collection covers a broad range of
              categories. Each point of interest is precisely geo-tagged and
              supplemented with relevant metadata to ensure accuracy and
              usefulness.
              <span className="d-block mt-2"></span>
              With our State-Of-The-Art Digitally Managed Data Dispensary,
              accessing and utilizing our Point Of Interest dataset is a
              seamless experience. Our user-friendly platform allows you to
              explore and retrieve location-specific data with ease. Whether
              you're a developer, researcher, or business owner, our dataset
              offers valuable insights to enhance your projects and
              applications.
              <span className="d-block mt-2"></span>
              Join our network today and gain access to our comprehensive Point
              Of Interest collection. Empower your location-based services,
              optimize navigation algorithms, or enrich your applications with
              real-world data. Phoenix Labs is here to support your endeavors
              and help you make informed decisions using our ethically sourced
              and meticulously curated datasets.
            </p>
          </div>
          <div className="m-4 p-3" id="syntheticData">
            <h1 className="mt-4">Synthetic Data</h1>
            <p className="text-justify">
              <span className="d-block mt-2"></span>
              Welcome to Phoenix Labs' Synthetic Data page! Our Synthetic Data
              collection provides a powerful resource for individuals,
              businesses, and organizations seeking versatile and customizable
              datasets. Synthetic data offers numerous advantages, including the
              ability to generate data that mimics real-world scenarios, while
              providing full control over its characteristics and distribution.
              <span className="d-block mt-2"></span>
              Our Synthetic Data collection covers various data types, including
              speech, text, image, and video. By leveraging our State-Of-The-Art
              Digitally Managed Data Dispensary, you can easily access and
              generate synthetic datasets tailored to your specific
              requirements. Whether you're training machine learning models,
              conducting simulations, or testing algorithms, our synthetic data
              empowers you to create realistic and scalable datasets.
              <span className="d-block mt-2"></span>
              At Phoenix Labs, we understand the importance of ethical and
              reliable data. Our synthetic data generation process adheres to
              rigorous quality standards, ensuring that the datasets we offer
              are accurate and representative of real-world scenarios. By
              utilizing our Synthetic Data collection, you can confidently drive
              innovation and advance your projects.
              <span className="d-block mt-2"></span>
              Join our network today and unlock the potential of synthetic data.
              With our cutting-edge tools and comprehensive collection, you can
              enhance your research, development, and testing processes.
              Experience the freedom and flexibility of working with synthetic
              data, backed by Phoenix Labs' commitment to delivering
              high-quality datasets for any use case.
            </p>
          </div>
          <div className="p-4 d-flex justify-content-evenly">
            <a
              className="btn btn-primary btn-lg m-auto"
              style={{ color: "aliceblue", textDecoration: "none" }}
              href="/art"
            >
              Back
            </a>
            {/* <a className="btn btn-primary btn-lg m-auto" style={{ color: "aliceblue", textDecoration: "none" }} href="/dataCollection">
                            Enroll now
                        </a> */}
            <PayPal />
          </div>
        </main>
      </div>
    </>
  );
};

export default Data_collection;
