import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "../../styles/Register.css";
import { message } from "antd";
const Register = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const payload = {
        username,
        email,
        password,
      };
      await axios
        //   .post(
        //     "https://phoenixlabs.onrender.com/api/v1/auth/register",
        //     payload
        //   )
        .post("https://phoenix-labs.onrender.com/api/v1/auth/register", payload)
        // .post("https://phoenix-labs.onrender.com/api/v1/auth/register", payload)
        .then((response) => {
          setLoading(false);
          message.success(response.data.message);
          if (response.data.success) {
            navigate("/login");
          }
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(error.response.data.message);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };
  return (
    <div className="Register d-flex justify-content-center align-items-center">
      <div className="Layout">
        <p className="fs-1 mb-4 text-center">Create Account</p>

        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column justify-content-center align-items-center gap-2"
        >
          <input
            className="w-100 p-2 ps-3"
            type="username"
            autoComplete="username"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            required
            min={5}
          />
          <input
            className="w-100 p-2 ps-3"
            type="email"
            autoComplete="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="w-100 position-relative">
            {showPassword ? (
              <i
                className="bi bi-eye-slash-fill position-absolute  "
                onClick={() => setShowPassword(false)}
              ></i>
            ) : (
              <i
                className="bi bi-eye-fill position-absolute  "
                onClick={() => setShowPassword(true)}
              ></i>
            )}
            <input
              className="w-100 p-2 ps-3 "
              type={`${showPassword ? "text" : "password"}`}
              autoComplete="current-password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              required
              min={8}
            />
          </div>
          {errorMessage && (
            <div className="text-center text-danger w-100 shakeError">
              {errorMessage}
            </div>
          )}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <div
                className=" w-100 text-center"
                style={{ fontSize: "0.8rem" }}
              >
                <span>Already have an account? </span>
                <a href="/login">Login..</a>
              </div>
              <button
                type="submit "
                className="btn btn-secondary btn-sm mt-2 ps-3 pe-3"
              >
                Create
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Register;
