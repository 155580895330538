import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import "./LandingDapps.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const EduMesh = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/cryptoTechnologyCenter">
                Home
              </a>
            </li>

            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Division <ArrowDropDownIcon />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps/greenchain"
                  >
                    GreenChain
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps/healthvault"
                  >
                    HealthVault
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps/foodsafe"
                  >
                    FoodSafe
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps/govtransparency"
                  >
                    GovTransparency
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="LandingDapps ">
        <div className="Header">
          <section>
            <h1 className="Heading">EduMesh®</h1>
            <button className="LandingDappsbutton">Learn More</button>
          </section>
        </div>
      </div>
    </>
  );
};

export default EduMesh;
