import React, { useEffect, useRef, useState } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import "./Contact.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const Contact = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  // Email send feature
  const [result, setResult] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResult("Sending....");
    const formData = new FormData(e.target);

    formData.append("access_key", process.env.REACT_APP_WEB3FORM_SUBMIT_KEY);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      setResult(res.message);
    } else {
      setResult(res.message);
    }

    await resetValues();
  };
  const resetValues = () => {
    setName("");
    setEmail("");
    setNumber("");
    setMessage("");
  };
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5 d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISION <ArrowDropDownIcon className="fs-2" />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter"
                  >
                    Crypto Technology Center
                  </a>
                </li>
                <li>
                  <a className="dropdown-item text-dark" href="/web">
                    Web/Mobile Application Suite
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="ContactUs py-5">
        <div className="m-0 pb-5">
          <h2 className="text-center fw-bold m-0">Contact Us</h2>
          <p className="text-center m-0">
            Any questions or remarks? Just write us a message!
          </p>
        </div>
        <div className="container bg-light rounded ">
          <div className="row p-2">
            <div className="col-12 col-md-6 col-lg-5 bg-black rounded p-5">
              <div>
                <h4 className="text-light m-0">Contact Information</h4>
                <p className="text-second m-0">
                  Write us a message to get in contact!
                </p>
              </div>
              <div className="my-4">
                <div className="my-3">
                  <span className="text-light">Helpline Number</span>
                  <div className="d-flex align-items-center gap-2 ">
                    <i class="fa-solid fa-phone text-second"></i>
                    <a href="tel:1-800-372-7052" className="text-second">
                      1-800-372-7052
                    </a>
                  </div>
                </div>
                <div className="my-3">
                  <span className="text-light">Press Inquiries</span>
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-envelope text-second"></i>
                    <a
                      href="mailto:press@phoenixlabsglobal.org"
                      className="text-second"
                    >
                      press@phoenixlabsglobal.org
                    </a>
                  </div>
                </div>
                <div className="my-3">
                  <span className="text-light">Investment Inquiries</span>
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-envelope text-second"></i>
                    <a
                      href="mailto:investments@phoenixlabsglobal.org"
                      className="text-second"
                    >
                      investments@phoenixlabsglobal.org
                    </a>
                  </div>
                </div>
                <div className="my-3">
                  <span className="text-light">Executive Assistant</span>
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa-solid fa-envelope text-second"></i>
                    <a
                      href="mailto:eaoffice@phoenixlabsglobal.org"
                      className="text-second"
                    >
                      eaoffice@phoenixlabsglobal.org
                    </a>
                  </div>
                </div>
              </div>
              <div className="social-links d-flex justify-content-center gap-4 py-2">
                <a
                  href="https://www.facebook.com/profile.php?id=100085708352952"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-facebook fs-5"></i>
                </a>

                <a
                  href="https://twitter.com/PhoenixLab94718"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-twitter fs-5"></i>
                </a>

                <a
                  href="https://www.tiktok.com/@phoenix.labs"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-tiktok fs-5"></i>
                </a>

                <a
                  href="https://www.instagram.com/phoenixlabs1/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram fs-5"></i>
                </a>

                <a
                  href="https://www.linkedin.com/company/labs-phoenix/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fa-brands fa-linkedin fs-5"></i>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-7">
              <div className="p-4">
                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    name="subject"
                    value="Somebody is trying to contact you from Phoenix Labs Website"
                  />
                  <div className="mb-2">
                    <label for="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label for="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-2">
                    <label for="number" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      name="number"
                      className="form-control"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </div>
                  <div className="mb-2">
                    <label for="number" className="form-label">
                      Email Subject
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="subject"
                    />
                  </div>
                  <div className="mb-2">
                    <label for="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      required
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div class="h-captcha" data-captcha="true"></div>
                  <input
                    type="hidden"
                    name="from_name"
                    value="Phoenix Labs Global"
                  />
                  {result ? (
                    <p className="text-success">{result}</p>
                  ) : (
                    <button
                      type="submit"
                      className="btn text-light d-flex align-items-center gap-1"
                      style={{ backgroundColor: "#000000" }}
                    >
                      Send{" "}
                      <i
                        className="fa-solid fa-paper-plane"
                        style={{ fontSize: "0.8rem" }}
                      ></i>
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
