import React, { useState } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import "../../styles/Classify.css";
const Classify = () => {
    const [inputText, setInputText] = useState("");
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const classifyText = async () => {
        setLoading(true);
        const options = {
            method: "GET",
            url: "https://twinword-text-classification.p.rapidapi.com/classify/",
            params: {
                text: inputText,
            },
            headers: {
                "X-RapidAPI-Key": process.env.REACT_APP_CLASSIFY_RAPID_API_KEY,
                "X-RapidAPI-Host": "twinword-text-classification.p.rapidapi.com",
            },
        };

        try {
            const response = await axios.request(options);
            setData(response.data);
            if (response.data.result_code !== "200") {
                setErrorMessage(response.data.result_msg);
            } else {
                setErrorMessage(null);
            }
            setLoading(false);
            console.log(response.data);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
        setLoading(false);
    };
    const categoryScorePairs = data.categories?.map((category) => ({ category, score: data.categories_scored[category] }));
    const keywordScorePairs = data.keywords?.map((keyword) => ({ keyword, score: data.keywords_scored[keyword] }));

    return (
        <div className="Classify d-flex flex-column align-items-center justify-content-center">
            <h1 className="text-secondary my-4">Data Classification</h1>
            <div className="Layout rounded">
                <textarea
                    className="rounded "
                    value={inputText}
                    placeholder="Enter Your Text Here..."
                    rows={8}
                    onChange={(e) => setInputText(e.target.value)}
                />
            </div>
            <div className="m-3 text-center">
                <button className="btn btn-primary px-5 fw-bold d-flex align-items-center" onClick={classifyText}>
                    {loading ? <CircularProgress className="text-light" /> : "Classify"}
                </button>
            </div>
            {errorMessage && <div className="text-danger mb-4 shakeError">{errorMessage}</div>}
            <div className="ResultLayout d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-center p-2">Classification Result</h2>
                <div className=" CategoriesSection rounded">
                    <div className="text-center fs-4">Categories</div>
                    <div className="d-flex gap-2 justify-content-center align-items-center flex-wrap CategoryList px-1 py-2">
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            categoryScorePairs?.map((category, i) => (
                                <div key={i} className="btn btn-success btn-sm rounded-pill Category fw-bold">
                                    {category.category} : {category.score}
                                </div>
                            ))
                        )}
                    </div>
                </div>
                <div className=" KeywordsSection rounded mt-4">
                    <div className="text-center fs-4">Keywords</div>
                    <div className="d-flex gap-2 justify-content-center align-items-center flex-wrap KeywordList px-1 py-2">
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            keywordScorePairs?.map((keyword, i) => (
                                <div key={i} className="btn btn-warning fw-bold btn-sm Keyword rounded-pill">
                                    {keyword.keyword} : {keyword.score}
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Classify;
