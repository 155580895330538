import "./styles/App.css";
import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "./components/Helpers/Footer";
import Home from "./components/pages/Home";
import Vision from "./components/pages/Vision/Vision";
import Art from "./components/pages/Art";
import CryptoTechnologyCenter from "./components/pages/CryptoTechnologyCenter/CryptoTechnologyCenter";
import Web from "./components/pages/Web";
import Projects from "./components/pages/Projects";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import DataCollection from "./components/pages/Data_collection";
import DataPreparation from "./components/pages/Data_preparation";
import ModelEvaluation from "./components/pages/ModelEvaluation";
import AiLabs from "./components/pages/AiLabs";
import AiLabsList from "./components/pages/AiLabsList";
import LearningLabs from "./components/pages/LearningLabs";
import HumanSP from "./components/pages/HumanSP";
import HistoricalClassification from "./components/pages/HistoricalClassifictaion";
import SkyLab from "./components/pages/SkyLab";
import IoLab from "./components/pages/IoLab";
import GLab from "./components/pages/GLab";
import XLab from "./components/pages/XLab";
import HumanOa from "./components/pages/HumanOa";
import DnaTracing from "./components/pages/DnaTracing";
import ArtificialRetrieval from "./components/pages/ArtificialRetrieval";
import FoodProduction from "./components/pages/FoodProduction";
import UrbanAg from "./components/pages/UrbanAg";
import Register from "./components/pages/Register";
import Login from "./components/pages/Login";
import Classify from "./components/pages/Classify";
import Annotate from "./components/pages/Annotate";
import Transcribe from "./components/pages/Transcribe";
import Translate from "./components/pages/Translate";
import Archives from "./components/pages/Archives";
import PdfPreview from "./components/pages/PdfPreview";
import AboutUs from "./components/pages/AboutUs/AboutUs";
import Membership from "./components/pages/Membership/Membership";
import Contact from "./components/pages/ContactUs/Contact";
import Career from "./components/pages/Career/Career";
import LegalCompliance from "./components/pages/LegalCompliance/LegalCompliance";
import PressKit from "./components/pages/PressKit/PressKit";
import Partnership from "./components/pages/Partnership/Partnership";
import NewsUpdates from "./components/pages/News&Updates/NewsUpdates";
import EducationalHub from "./components/pages/EducationalHub/EducationalHub";
import Events from "./components/pages/EventsWebinar/Events";
import ResearchDevelopment from "./components/pages/ResearchDevelopment/ResearchDevelopment";
import Dapps from "./components/pages/DApps/Dapps";
import Wallets from "./components/pages/WalletTechnologies/Wallets";
import Dao from "./components/pages/DAO/Dao";
import CryptoProducts from "./components/pages/CryptoProduct/CryptoProducts";
import CommunityForum from "./components/pages/CommunityForum/CommunityForum";
import CryptoTools from "./components/pages/CryptoTools/CryptoTools";
import MediChain from "./components/pages/FuturisticDapps/MediChain";
import CareToken from "./components/pages/FuturisticDapps/CareToken";
import TeleHealthHub from "./components/pages/FuturisticDapps/TeleHealthHub";
import PharmaTrust from "./components/pages/FuturisticDapps/PharmaTrust ";
import WellBeingDAO from "./components/pages/FuturisticDapps/WellBeingDAO";
import GreenChain from "./components/pages/LandingpagesDapps/GreenChain";
import HealthVault from "./components/pages/LandingpagesDapps/HealthVault";
import EduMesh from "./components/pages/LandingpagesDapps/EduMesh";
import GovTransparency from "./components/pages/LandingpagesDapps/GovTransparency";
import FoodSafe from "./components/pages/LandingpagesDapps/FoodSafe";
import Linphone from "./components/pages/Linphone";

//Labs made by Byte Down
// import AiIoLab from "./components/pages/AiLabs/AiIoLab/AiIoLab";
// import AiSkyLab from "./components/pages/AiLabs/AiSkyLab/AiSkyLab";
// import AiGLab from "./components/pages/AiLabs/AiGLab/AiGLab";
// import AiXLab from "./components/pages/AiLabs/AiXLab/AiXLab";
// import AiLearningLab from "./components/pages/AiLabs/AiLearningLab/AiLearningLab";

// Google Analytics Integration Code
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
ReactGA.send({ hitType: "pageview", page: "/login", title: "Login Page" });

ReactGA.send({
  hitType: "pageview",
  page: "/register",
  title: "Register Page",
});
ReactGA.send({
  hitType: "pageview",
  page: "/art",
  title: "Artificial Intelligence Center",
});
ReactGA.send({
  hitType: "pageview",
  page: "/web",
  title: "Web/Mobile Application Suite",
});
ReactGA.send({
  hitType: "pageview",
  page: "/cryptoTechnologyCenter",
  title: "Crypto Technology Center",
});

ReactGA.send({
  hitType: "pageview",
  page: "/register",
  title: "Register Page",
});
ReactGA.send({
  hitType: "pageview",
  page: "/art",
  title: "Artificial Intelligence Center",
});
ReactGA.send({
  hitType: "pageview",
  page: "/web",
  title: "Web/Mobile Application Suite",
});
ReactGA.send({
  hitType: "pageview",
  page: "/cryptoTechnologyCenter",
  title: "Crypto Technology Center",
});

// Google Analytics Code Ends Here
function App() {
  useEffect(() => {
    const route = window.location.href;
    if (route.includes("/art")) {
      if (!localStorage.getItem("user_phoenixlab_user")) {
        window.location.href = "/login";
      }
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/web" element={<Web />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/art" element={<Art />} />
        <Route path="/art/dataCollection" element={<DataCollection />} />
        <Route path="/art/dataPreparation" element={<DataPreparation />} />
        <Route path="/art/dataPreparation/classify" element={<Classify />} />
        <Route path="/art/dataPreparation/annotate" element={<Annotate />} />
        <Route
          path="/art/dataPreparation/transcribe"
          element={<Transcribe />}
        />
        <Route path="/art/dataPreparation/translate" element={<Translate />} />
        <Route path="/art/modelEvaluation" element={<ModelEvaluation />} />
        <Route path="/art/ailabs" element={<AiLabs />} />
        <Route path="/art/aiLabsList" element={<AiLabsList />} />
        <Route path="/art/aiLabsList/learningLab" element={<LearningLabs />} />
        <Route path="/art/aiLabsList/skyLab" element={<SkyLab />} />
        <Route
          path="/art/aiLabsList/skyLab/phoenix-uplink"
          element={<Linphone />}
        />
        <Route path="/art/aiLabsList/ioLab" element={<IoLab />} />
        <Route path="/art/aiLabsList/xLab" element={<XLab />} />
        <Route path="/art/aiLabsList/gLab" element={<GLab />} />

        <Route
          path="/art/aiLabsList/ioLab/artificialRetrieval"
          element={<ArtificialRetrieval />}
        />
        <Route
          path="/art/aiLabsList/gLab/dnaTracing"
          element={<DnaTracing />}
        />
        <Route
          path="/art/aiLabsList/humanSustainabilityProject"
          element={<HumanSP />}
        />
        <Route
          path="/art/aiLabsList/humanSustainabilityProject/foodProduction"
          element={<FoodProduction />}
        />
        <Route
          path="/art/aiLabsList/humanSustainabilityProject/urbanAg"
          element={<UrbanAg />}
        />
        <Route
          path="/art/aiLabsList/historicalClassification"
          element={<HistoricalClassification />}
        />
        <Route
          path="/art/aiLabsList/historicalClassification/humanOA"
          element={<HumanOa />}
        />
        {/* <Route path="/aiLabs/ioLab" element={<AiIoLab />} />
        <Route path="/aiLabs/skyLab" element={<AiSkyLab />} />
        <Route path="/aiLabs/gLab" element={<AiGLab />} />
        <Route path="/aiLabs/xLab" element={<AiXLab />} />
        <Route path="/aiLabs/learningLab" element={<AiLearningLab />} /> */}
        <Route
          path="/cryptoTechnologyCenter"
          element={<CryptoTechnologyCenter />}
        />
        <Route
          path="/cryptoTechnologyCenter/educationalHub"
          element={<EducationalHub />}
        />
        <Route path="/cryptoTechnologyCenter/events" element={<Events />} />
        <Route
          path="/cryptoTechnologyCenter/researchDevelopment"
          element={<ResearchDevelopment />}
        />
        <Route path="/cryptoTechnologyCenter/Dapps" element={<Dapps />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/cryptoTechnologyCenter/Dao" element={<Dao />} />
        <Route
          path="/cryptoTechnologyCenter/walletTechnologies"
          element={<Wallets />}
        />
        <Route
          path="/cryptoTechnologyCenter/cryptoProducts"
          element={<CryptoProducts />}
        />
        <Route
          path="/cryptoTechnologyCenter/communityForum"
          element={<CommunityForum />}
        />
        <Route
          path="/cryptoTechnologyCenter/cryptotools"
          element={<CryptoTools />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/Medichain"
          element={<MediChain />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/Caretoken"
          element={<CareToken />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/Telehealthhub"
          element={<TeleHealthHub />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/Pharmatrust"
          element={<PharmaTrust />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/Wellbeingdao"
          element={<WellBeingDAO />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/greenchain"
          element={<GreenChain />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/healthvault"
          element={<HealthVault />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/edumesh"
          element={<EduMesh />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/govtransparency"
          element={<GovTransparency />}
        />
        <Route
          path="/cryptoTechnologyCenter/Dapps/foodsafe"
          element={<FoodSafe />}
        />

        <Route path="/research-papers" element={<Archives />} />
        <Route path="/preview/:id" element={<PdfPreview />} />
        <Route
          path="/register"
          element={
            <ProtectRegister>
              <Register />
            </ProtectRegister>
          }
        />
        <Route
          path="/login"
          element={
            <ProtectLogin>
              <Login />
            </ProtectLogin>
          }
        />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/legalCompliance" element={<LegalCompliance />} />
        <Route path="/presskit" element={<PressKit />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/newsupdates" element={<NewsUpdates />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

// If user is logined in, and trying to access login page again, redirect to home page
const ProtectLogin = (props) => {
  if (localStorage.getItem("user_phoenixlab_user")) {
    return <Navigate to="/" />;
  } else {
    return props.children;
  }
};
// If user is logined in, and trying to access register page again, redirect to home page
const ProtectRegister = (props) => {
  if (localStorage.getItem("user_phoenixlab_user")) {
    return <Navigate to="/" />;
  } else {
    return props.children;
  }
};
