import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/Archives.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const Archives = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  const pdfUrl = [
    {
      id: 1,
      topic: "Equity AI",
      name: "Navigating Complex Identities: Unveiling the Significance of Intersectionality in AI Ethics",
      url: "https://docs.google.com/document/d/1yk8xvorzteZJYyx4A7wTSJDTTj6AN7tgJxQG5a_uO-U/preview?usp=sharing",
    },
    {
      id: 2,
      topic: "Equity AI",
      name: "A Blueprint for Equitable Systems: Harnessing AI for Social Justice",
      url: "https://docs.google.com/document/d/1NeIjuRFXTcsbUqaX7Q2Aqz7ebffps8DUzjJYdbDSbLM/preview?usp=sharing",
    },
    {
      id: 3,
      topic: "Equity AI",
      name: "Bridging the Gap: Paving the Way for Gender Equality in AI",
      url: "https://docs.google.com/document/d/1NeIjuRFXTcsbUqaX7Q2Aqz7ebffps8DUzjJYdbDSbLM/edit?preview=sharing",
    },
    {
      id: 4,
      topic: "Equity AI",
      name: "Empowering Tomorrow: The Crucial Role of Education in Ensuring AI Equity",
      url: "https://docs.google.com/document/d/11uvtqcfISACSv7l_koOdhEk5-abip_-rNyG4vcFg8Ig/preview?usp=sharing",
    },
    {
      id: 5,
      topic: "Equity AI",
      name: "Navigating the Workforce Landscape: Ethical Considerations in AI Hiring Algorithms",
      url: "https://docs.google.com/document/d/1l12V25dGgBxaOLW8b3ptph16btLd6DRbG3y4bhKJkjM/preview?usp=sharing",
    },
    {
      id: 6,
      topic: "Equity AI",
      name: "Fostering Inclusivity: Disability-Inclusive AI Beyond Accessibility Compliance",
      url: "https://docs.google.com/document/d/1jve9kxI6sVo6F51o8Uo7YkUVfBXSncQ1dkx_TGsyH9g/preview?usp=sharing",
    },
    {
      id: 7,
      topic: "Equity AI",
      name: "Bridging Gaps in Healthcare: Tackling AI Bias to Address Disparities",
      url: "https://docs.google.com/document/d/1f1VOa67F2TiSvIHSzo4w48AT2vVaZZBBVmxh6DKeQCw/preview?usp=sharing",
    },
    {
      id: 8,
      topic: "Equity AI",
      name: "Embracing Diversity: Cultural Sensitivity in AI Beyond One-Size-Fits-All Solutions",
      url: "https://docs.google.com/document/d/1W3c6-MrqUlAA13ui6XG9UKgvjrFDVxW0-9AW1_G9eRY/preview?usp=sharing",
    },
    {
      id: 9,
      topic: "Equity AI",
      name: "Ensuring Fairness in AI Criminal Justice Applications: A Call for Ethical Implementation",
      url: "https://docs.google.com/document/d/1gSirMWV30IdjVA9-5pNCZjJhf6FkZYAdam8att5-LP8/preview?usp=sharing",
    },
    {
      id: 10,
      topic: "Equity AI",
      name: "Bridging the Divide: The Impact of Socioeconomic Factors on AI Access",
      url: "https://docs.google.com/document/d/1MKl0MMKg-qB8PcRqt1fgOySfEYDhDxIIYERjiLcMsMQ/preview?usp=sharing",
    },
  ];

  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5 d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISION <ArrowDropDownIcon className="fs-2" />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter"
                  >
                    Crypto Technology Center
                  </a>
                </li>
                <li>
                  <a className="dropdown-item text-dark" href="/web">
                    Web/Mobile Application Suite
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="Archives" ref={contentRef}>
        <div className="text-center">
          <h2 className="p-2">Phoenix Labs Research Center</h2>
        </div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            {pdfUrl.map((item, index) => {
              return (
                <div key={index} className="card text-center col-3 m-2 p-3">
                  <h6>{item.name}</h6>
                  <div
                    className="d-flex justify-content-between align-items-center p-1"
                    style={{ borderTop: "1px solid #00000050" }}
                  >
                    <p className="m-0" style={{ fontSize: "0.9rem" }}>
                      <span className="fw-bold">Topic:</span> {item.topic}
                    </p>
                    <a
                      className="btn btn-primary btn-sm"
                      href={`/preview/${item.id}`}
                    >
                      View
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default Archives;
