import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import front_video from "../images/front_video.mp4";
import "../../styles/Home.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";

const Home = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);

  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0">
              <a
                className="dropdown text-white fs-5 d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISIONS <ArrowDropDownIcon className="fs-2" />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="https://crypto-technology-center.netlify.app/"
                    rel="noopener noreferrer"
                  >
                    Crypto Technology Center
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown px-5 py-2 p-md-0">
              <a
                className="dropdown text-white fs-5 d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLinkAI"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                AI LABS <ArrowDropDownIcon className="fs-2" />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLinkAI"
              >
                <li>
                  <Link
                    className="dropdown-item text-dark"
                    to="/art/aiLabsList/ioLab"
                  >
                    IO Lab
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item text-dark"
                    to="/art/aiLabsList/skyLab"
                  >
                    Sky Lab
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item text-dark"
                    to="/art/aiLabsList/gLab"
                  >
                    G-Lab
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item text-dark"
                    to="/art/aiLabsList/xLab"
                  >
                    X-Lab
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item text-dark"
                    to="/art/aiLabsList/learningLab"
                  >
                    Learning Lab
                  </Link>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div ref={contentRef} className="Home">
        <div className="backLayer">
          <video autoPlay loop muted className="video">
            <source src={front_video} type="video/mp4" />
          </video>
        </div>
        <div className="frontLayer w-100">
          <h1>Phoenix Labs</h1>
          <p>
            Phoenix Labs operates a high-growth platform that enables our global
            network to innovate and execute. We build, expand, and improve upon
            world-class AI, Crypto, and Web/Mobile application initiatives.
          </p>
          <button type="button" className="btn btn-primary btn-md">
            <a
              style={{ color: "aliceblue", textDecoration: "none" }}
              href="/Vision"
            >
              Learn More
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;
