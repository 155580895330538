import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/HistoricalClassification.css";
const HistoricalClassification = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white fs-5"
                aria-current="page"
                href="/art/aiLabsList/historicalClassification/humanOA"
              >
                Human Ownership Archives
              </a>
            </li>

            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className="text-white fs-5"
                href="https://phoenixliberationproject.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                Phoenix Liberation Project
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div ref={contentRef} className="historicalC">
        <div className="header">
          <section className=" w-100 d-flex align-items-center">
            <h1 className="w-75 shadow m-auto text-center">
              Historical Classification
            </h1>
          </section>
        </div>
        <div className="card m-5 p-4 shadow-lg">
          <p>
            Welcome to the Historical Classification section, where we celebrate
            the legacy of Phoenix Labs and our pivotal contributions to shaping
            the world through technology and innovation. Our historical projects
            showcase a journey marked by curiosity, compassion, and an
            unwavering commitment to human progress.
          </p>
        </div>
        <div className=" card m-5 p-4">
          <ul>
            <li>
              <a href="/art/aiLabsList/historicalClassification/humanOA">
                Human Ownership Archives
              </a>
            </li>
            <li>
              <a
                href="https://phoenixliberationproject.netlify.app/"
                target="_blank"
                rel="noreferrer"
              >
                Phoenix Liberation Project
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HistoricalClassification;
