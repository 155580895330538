import React, { useRef, useEffect } from "react";
import "./EducationalHub.css";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "../../../styles/Navbar.css";
const EducationalHub = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white"
                aria-current="page"
                href="/cryptoTechnologyCenter/"
              >
                Home
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/cryptoTechnologyCenter/events">
                Events & Webinars
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/membership">
                Membership
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Division <ArrowDropDownIcon />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/researchDevelopment"
                  >
                    Research & Development
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps"
                  >
                    DApp's Showcase
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/walletTechnologies"
                  >
                    Wallet Technologies
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dao"
                  >
                    DAO
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptoProducts"
                  >
                    Crypto Products/Services
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/communityForum"
                  >
                    Community Forum
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptotools"
                  >
                    Crypto Currency Tools
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="EducationalHub">
        <div className="Header">
          <section>
            <h1 className="Heading">Educational Hub</h1>
          </section>
        </div>
        <div className="Content">
          <section className="card p-4 shadow-lg m-2">
            <h3> Overview of Educational Programs</h3>
            <p>
              Embark on a knowledge expedition with our comprehensive
              educational programs. From foundational concepts to advanced
              topics, our courses cover a spectrum of subjects, providing a
              holistic understanding of crypto technologies and their
              applications.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Courses on Distributed Ledger Technology (DLT)</h3>
            <p>
              Delve into the heart of blockchain with our specialized courses on
              Distributed Ledger Technology. Our curriculum, designed by
              industry experts, equips you with the knowledge and skills needed
              to navigate the intricate world of decentralized systems.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>AI and Crypto Research</h3>
            <p>
              Explore the intersection of artificial intelligence and crypto
              through our groundbreaking research initiatives. Uncover the
              synergies between these two transformative technologies and stay
              ahead of the curve in this ever-evolving landscape.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Workshops on Wallets, DAO, and Other Topics</h3>
            <p>
              Participate in hands-on workshops covering a myriad of topics,
              including wallet technologies, decentralized autonomous
              organizations (DAOs), and more. Gain practical insights and hone
              your skills under the guidance of industry professionals.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Certification Programs </h3>
            <p>
              Elevate your expertise with our certification programs. Validate
              your knowledge and skills in the crypto and blockchain domains,
              gaining recognition for your commitment to excellence.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default EducationalHub;
