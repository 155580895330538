import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Linphone = () => {
  const token = localStorage.getItem("user_phoenixlab_user");

  const [contacts, setContacts] = useState([]);
  const [contactName, setContactName] = useState("");
  const [contactSipAddress, setContactSipAddress] = useState("");
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [userSipAddress, setUserSipAddress] = useState("");
  const [message, setMessage] = useState("");

  const handleAddClick = () => {
    setShowAddContactForm(true);
  };

  const handleAddContact = async () => {
    try {
      const newContact = {
        contactName: contactName,
        contactSipAddress: contactSipAddress,
      };
      const res = await axios.post(
        "https://phoenix-labs.onrender.com/api/v1/linphone/contact/createContact",
        newContact,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessage(res.data.message);
      setContacts([...contacts, newContact]);
      setShowAddContactForm(false);
      setContactName("");
      setContactSipAddress("");
    } catch (error) {
      console.error("Error adding contact:", error);
    }
  };

  const handleCancel = () => {
    setShowAddContactForm(false);
    setContactName("");
    setContactSipAddress("");
  };

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        if (!token) {
          console.error("No token found in local storage.");
          return;
        }

        const res = await axios.get(
          "https://phoenix-labs.onrender.com/api/v1/linphone/contact/getContacts",
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setContacts(res.data.contacts);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error("Invalid or expired token");
          setMessage("Invalid or expired token");
        } else {
          console.error("Error in fetching contacts: ", error.message);
          setMessage(
            error.response?.data?.message || "Error in fetching contacts"
          );
        }
      }
    };

    fetchContacts();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-r from-purple-500 to-indigo-500 p-6 flex flex-col items-center">
      <Link
        to="/art/aiLabsList/skyLab"
        className="mb-4 text-white font-bold underline hover:text-gray-200"
      >
        Back to Sky Lab
      </Link>

      <h1 className="text-4xl font-extrabold text-white mb-8">
        Phoenix Uplink
      </h1>

      <div className="mb-6 space-x-4">
        <a
          href="https://docs.google.com/document/d/1q9SY-3hIMA5F-QT_mXPIJQxfrcOa2i56/edit"
          target="_blank"
          rel="noreferrer"
          className="px-6 py-3 bg-white text-indigo-600 font-semibold rounded-lg shadow-md hover:bg-gray-100 transition-all"
        >
          Uplink Guide
        </a>
        <a
          href={`sip:${userSipAddress}`}
          className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-500 transition-all"
        >
          Open Linphone
        </a>
      </div>

      <p className="text-red-500 text-center my-4 text-lg">{message}</p>

      <div className="bg-white rounded-lg shadow-lg p-6 mb-8 w-full max-w-md">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Make a Call
        </h2>
        <input
          type="text"
          className="w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onChange={(e) => setUserSipAddress(e.target.value)}
          placeholder="username@sip.linphone.org"
        />
        <a
          href={`sip:${userSipAddress}`}
          className="block w-full py-3 bg-indigo-600 text-white font-bold rounded-lg shadow-md text-center hover:bg-indigo-500 transition-all"
        >
          Call
        </a>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">Contacts</h2>
          <button
            onClick={handleAddClick}
            className="px-4 py-2 bg-indigo-600 text-white font-bold rounded-lg shadow-md hover:bg-indigo-500 transition-all"
          >
            Add Contact
          </button>
        </div>
        <div className="space-y-4">
          {contacts.map((contact, index) => (
            <div
              key={index}
              className="flex justify-between items-center p-4 bg-gray-100 rounded-lg"
            >
              <span className="font-semibold text-gray-800">
                {contact.contactName}
              </span>
              <span className="text-gray-600">{contact.contactSipAddress}</span>
            </div>
          ))}
        </div>
      </div>

      {showAddContactForm && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-sm shadow-lg">
            <h3 className="text-xl font-bold mb-4">Add Contact</h3>
            <input
              className="w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              type="text"
              placeholder="Name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
            <input
              className="w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              type="text"
              placeholder="SIP Address"
              value={contactSipAddress}
              onChange={(e) => setContactSipAddress(e.target.value)}
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-600 text-white font-bold rounded-lg hover:bg-gray-500 transition-all"
              >
                Cancel
              </button>
              <button
                onClick={handleAddContact}
                className="px-4 py-2 bg-indigo-600 text-white font-bold rounded-lg hover:bg-indigo-500 transition-all"
              >
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Linphone;
