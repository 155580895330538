import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/HumanSP.css";
const HumanSP = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white fs-5"
                aria-current="page"
                href="/art/aiLabsList/humanSustainabilityProject"
              >
                Healthcare
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className="text-white fs-5"
                href="/art/aiLabsList/humanSustainabilityProject"
              >
                UniCare<sup style={{ fontSize: "0.8rem" }}>TM</sup>
              </a>
            </li>

            <li className="nav-item px-5 py-2 p-md-0">
              <a
                className="text-white fs-5"
                href="/art/aiLabsList/humanSustainabilityProject/urbanAg"
              >
                Urban Ag
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0">
              <a
                className="text-white fs-5"
                href="/art/aiLabsList/humanSustainabilityProject"
              >
                Food Security
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0">
              <a
                className="text-white fs-5"
                href="/art/aiLabsList/humanSustainabilityProject/foodProduction"
              >
                Food Production
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="humanSP">
        <div className="header">
          <section className=" w-100 d-flex align-items-center">
            <h1 className="w-75 m-auto text-center shadow-lg">
              Human Sustainability Projects
            </h1>
          </section>
        </div>
        <div className="card m-5 p-4 shadow">
          <p>
            The Human Sustainability Project exemplifies our dedication to
            leveraging AI for the betterment of humanity. This visionary
            initiative focuses on projects aimed at addressing global
            challenges, from healthcare to environmental sustainability. Through
            collaboration and innovation, we envision a future where AI-driven
            solutions drive lasting positive change for all.
          </p>
        </div>
        <div className=" card m-5 p-4">
          <ul>
            <li>
              <a href="/art/aiLabsList/humanSustainabilityProject">
                HealthCare
              </a>
            </li>
            <li>
              <a href="/art/aiLabsList/humanSustainabilityProject/urbanAg">
                Urban Agriculture
              </a>
            </li>
            <li>
              <a href="/art/aiLabsList/humanSustainabilityProject">UniCare</a>
            </li>
            <li>
              <a href="/art/aiLabsList/humanSustainabilityProject/foodProduction">
                Food Production{" "}
              </a>
            </li>
            <li>
              <a href="/art/aiLabsList/humanSustainabilityProject">
                Food Security
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HumanSP;
