import data_collection from "../images/data_collection.jpg";
import data_preparation from "../images/data_preparation.jpg";
import model_evaluation from "../images/model_evaluation.jpg";
import ailabs from "../images/ailabs.jpg";
import "../../styles/Art.css";
import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
const Art = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className=" text-white fs-5" aria-current="page" href="/">
                HOME
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISIONS
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter"
                  >
                    Crypto Technology Center
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="ART">
        <div className="header">
          <div>
            <h1 className="Heading">Artificial Intelligence Center</h1>
            <p className="SubHeading">
              Phoenix Labs Artificial Intelligence Center was created to further
              AI research and development. We believe that AI Research, tools,
              and laboratories should be accessible to marginalized groups. It
              is our goal to make the tools (code, datasets, models, etc.), and
              spaces available so that the aforementioned can easily access and
              use them to solve pressing problems.
            </p>
          </div>
        </div>
        <main className="d-flex align-items-center justify-content-center">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-3 text-center ">
                <a
                  href="/art/dataCollection"
                  className="d-flex justify-content-center align-items-center card"
                >
                  <img
                    className="CoverImg"
                    src={data_collection}
                    alt="Data Collection"
                  />
                  <h4 className="mt-3">Data Collection</h4>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-md-3 text-center">
                <a
                  href="/art/dataPreparation"
                  className="d-flex justify-content-center align-items-center card"
                >
                  <img
                    className="CoverImg"
                    src={data_preparation}
                    alt="Data Preparation"
                  />
                  <h4 className="mt-3">Data Preparation</h4>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-md-3 text-center">
                <a
                  href="/art/modelEvaluation"
                  className="d-flex justify-content-center align-items-center card"
                >
                  <img
                    className="CoverImg"
                    src={model_evaluation}
                    alt="Model Evaluation"
                  />
                  <h4 className="mt-3">Model Evaluation</h4>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-md-3 text-center">
                <a
                  href="/art/aiLabs"
                  className="d-flex justify-content-center align-items-center card"
                >
                  <img className="CoverImg" src={ailabs} alt="Ai Labs" />
                  <h4 className="mt-3">AI Labs</h4>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Art;
