import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import "./AboutUs.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const AboutUs = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5 d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISION <ArrowDropDownIcon className="fs-2" />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter"
                  >
                    Crypto Technology Center
                  </a>
                </li>
                <li>
                  <a className="dropdown-item text-dark" href="/web">
                    Web/Mobile Application Suite
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="AboutUs">
        <div className="Header">
          <section>
            <h1 className="Heading">ABOUT US</h1>
          </section>
        </div>
        <div className="Content">
          <p className="p-4  m-2">
            At Phoenix Labs, we stand at the forefront of global technology,
            dedicated to crafting innovative solutions that tackle real-world
            challenges, including the poverty/wealth gap, disease, and equity.
            Our commitment is unwavering, as we harness the transformative power
            of artificial intelligence to create cutting-edge solutions that
            make a positive impact on people's lives.
          </p>
          <section className="card p-4 shadow-lg m-2">
            <h3>MISSION</h3>
            <p>
              Phoenix Labs is on a mission to redefine possibilities through
              technology. Our goal is to drive positive change by leveraging AI
              to address societal challenges, fostering inclusivity, and
              empowering communities worldwide.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>HISTORY OF PHOENIX LABS</h3>
            <p>
              Founded on a vision of technological excellence, Phoenix Labs has
              a rich history of groundbreaking achievements. From our humble
              beginnings to becoming a global force, our journey is marked by a
              relentless pursuit of innovation and a commitment to shaping a
              better future through technology.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3> COMMITMENT TO AI EQUITY AND INCLUSION</h3>
            <p>
              At Phoenix Labs, we believe in the ethical use of AI. Our
              commitment to equity and inclusion is embedded in every facet of
              our work. We actively strive to mitigate biases and ensure that
              our technologies contribute to a more equitable and diverse world.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>FOUNDING PRINCIPLES</h3>
            <p>
              Our founding principles are the bedrock of Phoenix Labs. They
              guide our decisions, inspire our teams, and drive our pursuit of
              excellence. Transparency, integrity, and a passion for positive
              impact define who we are and how we approach every endeavor.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
