import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
// import ChatBot from "react-simple-chatbot";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/IoLab.css";
const IoLab = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);

  // Chatbot Code
  // const steps = [
  //   {
  //     id: "1",
  //     message: "What is your name?",
  //     trigger: "2",
  //   },
  //   {
  //     id: "2",
  //     user: true,
  //     trigger: "3",
  //   },
  //   {
  //     id: "3",
  //     message: "Hi {previousValue}, Welcome to IO Lab!",
  //     trigger: "4",
  //   },
  //   {
  //     id: "4",
  //     message: "Level 2 verification is required to proceed beyond this point.",
  //     trigger: "5",
  //   },
  //   {
  //     id: "5",
  //     message: "Please complete the following biometric authentication.",
  //     trigger: "6",
  //   },
  //   {
  //     id: "6",
  //     message: "Thank You for using me! We are currently in development phase.",
  //     end: true,
  //   },
  // ];
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 w-md-0 d-flex justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-between align-items-center w-100 ">
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/ioLab">
                Computer Vision
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/ioLab">
                Natural Language Processing
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/ioLab">
                Data Analytics
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/ioLab">
                Automation & Robotics
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a
                className="text-white "
                href="/art/aiLabsList/ioLab/artificialRetrieval"
              >
                Artificial Retrieval
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center">
              <a className="text-white " href="/art/aiLabsList/ioLab/">
                Data Streams
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center mx-2">
              <a className="text-white " href="/art/aiLabsList/ioLab/">
                Analytics
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center mx-2 ">
              <a className="text-white " href="/art/aiLabsList/ioLab/">
                Collaboration
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 text-center ">
              <a className="text-white " href="/art/aiLabsList/ioLab/">
                About
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div ref={contentRef} className="ioLab">
        <div className="header">
          <section className=" w-100 d-flex align-items-center">
            <h1 className=" w-75 m-auto text-center">IO Lab</h1>
          </section>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center"> AI Model Generation/Deployment</h4>
          <p className="text-justify">
            <br />
            Welcome to IO Lab, where artificial intelligence is brought to life.
            This is where our exceptional team of AI engineers and data
            scientists work tirelessly to develop cutting-edge AI models that
            redefine industries. We believe in the power of AI to drive
            meaningful change, and IO Lab is the heart of that transformation.
            <br />
            <br />
            In IO Lab, we harness the power of artificial intelligence to shape
            the future. Here, our team of AI engineers and data scientists work
            tirelessly to develop cutting-edge AI models that redefine
            industries. Public users of this lab can easily create and deploy AI
            models, democratizing the potential of AI for everyone.
            <br />
            <br />
            With a focus on robust model generation and seamless deployment, IO
            Lab ensures that our AI solutions are not just accurate but also
            scalable and adaptable to diverse applications. We strive to create
            AI that understands, learns, and augments human capabilities, making
            the world a better place through intelligence.
          </p>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center"> About</h4>
          <p className="text-justify">
            <br />
            Witness the collaborative spirit at IO Lab. Engage with like-minded
            individuals, businesses, and organizations to foster innovation.
            Together, we can push the boundaries of what AI can achieve,
            creating a future where intelligence knows no limits.
            <br />
          </p>
          <video width="100%" height="440" controls>
            <source
              src="https://gateway.pinata.cloud/ipfs/QmXFApE1XHNnD5FPMjXRp2hzGL2e5vsqhkmRqwE5BHaag3"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center"> Data Streams</h4>
          <p className="text-justify">
            <br />
            Dive into the vast ocean of data streams that fuel our AI models.
            Discover how we gather, process, and refine data to create models
            that adapt and evolve in real-time. IO Lab is where data meets
            intelligence, creating a synergy that powers the future.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center"> Analytics</h4>
          <p className="text-justify">
            <br />
            Explore the analytics behind our AI models. See how they learn,
            adapt, and optimize, providing unparalleled insights. IO Lab is not
            just about AI models; it's about understanding the data-driven
            intelligence that shapes our world.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h4 className="text-center"> Collaboration</h4>
          <p className="text-justify">
            <br />
            Witness the collaborative spirit at IO Lab. Engage with like-minded
            individuals, businesses, and organizations to foster innovation.
            Together, we can push the boundaries of what AI can achieve,
            creating a future where intelligence knows no limits.
            <br />
          </p>
        </div>
      </div>
      <div>
        {/* <ThemeProvider theme={theme}> */}
        {/* <ChatBot steps={steps} floating={true} />  */}
        {/* </ThemeProvider> */}
      </div>
    </>
  );
};

export default IoLab;
