import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
//chatbot code
// import ChatBot from "react-simple-chatbot";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/GLab.css";
const GLab = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  // Chatbot Code
  // const steps = [
  //   {
  //     id: "1",
  //     message: "What is your name?",
  //     trigger: "2",
  //   },
  //   {
  //     id: "2",
  //     user: true,
  //     trigger: "3",
  //   },
  //   {
  //     id: "3",
  //     message: "Hi {previousValue}, Welcome to G Lab!",
  //     trigger: "4",
  //   },
  //   {
  //     id: "4",
  //     message: "Level 2 verification is required to proceed beyond this point.",
  //     trigger: "5",
  //   },
  //   {
  //     id: "5",
  //     message: "Please complete the following biometric authentication.",
  //     trigger: "6",
  //   },
  //   {
  //     id: "6",
  //     message: "Thank You for using me! We are currently in development phase.",
  //     end: true,
  //   },
  // ];
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-between align-items-center w-100 ">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/art/aiLabsList/gLab">
                Genealogy
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/art/aiLabsList/gLab">
                Neutrogenomics
              </a>
            </li>

            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className="text-white fs-5"
                href="/art/aiLabsList/gLab/dnaTracing"
              >
                DNA Tracing
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/art/aiLabsList/gLab/">
                Experiments
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/art/aiLabsList/gLab/">
                Ai Models
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div ref={contentRef} className="gLab">
        <div className="header">
          <section className=" w-100 d-flex align-items-center">
            <h1 className="w-75 m-auto text-center">G Lab</h1>
          </section>
        </div>
        <div className="card m-5 p-4">
          <h1 className="text-center">
            Genealogy/Genomic Research and Development
          </h1>
          <p className="text-justify">
            <br />
            Unlocking the secrets of life itself, G Lab is at the forefront of
            genealogy and genomic research. Here, we delve into the intricate
            code of life, exploring how AI can accelerate breakthroughs in
            healthcare, agriculture, and beyond. Our work is driven by the
            belief that genomics holds the key to a healthier, more sustainable
            future.
            <br />
            Through collaborations with leading geneticists and
            bioinformaticians, G Lab is uncovering the hidden potential of
            genetic data. From personalized medicine to crop resilience, we are
            harnessing the power of AI to decode the language of life and shape
            a brighter tomorrow.
            <br />
            In G-Lab, we unravel the intricate code of life, exploring how AI
            can accelerate breakthroughs in healthcare, DNA tracing, family-tree
            generation, and genetic analysis. Public users of this lab can trace
            their lineage and generate family trees, connecting with their
            genetic heritage in unprecedented ways.
          </p>
          <video controls width="100%" height="440px">
            <source
              src="https://gateway.pinata.cloud/ipfs/QmYNhrxK9xK8HaMsYCeBHnGBnpc1qXzi9GyeGPPPWAYT7a"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="card m-5 p-4">
          <h1 className="text-center">Experiments</h1>
          <p className="text-justify">
            <br />
            Embark on a journey of genetic discovery through our experiments.
            See how AI is revolutionizing genealogy research, unlocking secrets
            buried in our DNA. G-Lab is where the past meets the future, shaping
            the narrative of our genetic history.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h1 className="text-center">AI Models</h1>
          <p className="text-justify">
            <br />
            Delve into the AI models that power our genetic research. Witness
            the precision and accuracy that AI brings to genealogical analysis,
            paving the way for breakthroughs in personalized medicine and
            beyond.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h1 className="text-center">Forums</h1>
          <p className="text-justify">
            <br />
            Join the conversation in our forums. Connect with fellow genetic
            enthusiasts, share stories, and exchange knowledge. G-Lab is not
            just a place for research; it's a community where the curious minds
            of today shape the genetic landscapes of tomorrow.
            <br />
          </p>
        </div>
        <div className="card m-5 p-4">
          <h1 className="text-center">Support</h1>
          <p className="text-justify">
            <br />
            Need assistance in tracing your genetic roots? Our support team is
            here to guide you. From understanding your DNA results to navigating
            the world of genetic testing, G-Lab is committed to providing
            support every step of the way.
            <br />
          </p>
        </div>
      </div>
      <div>
        {/* <ThemeProvider theme={theme}> */}
        {/* <ChatBot steps={steps} floating={true} />  */}
        {/* </ThemeProvider> */}
      </div>
    </>
  );
};

export default GLab;
