import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../../styles/Ailabs.css";
import faceIO from "@faceio/fiojs";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";

const faceio = new faceIO(process.env.REACT_APP_FACEIO_KEY);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 330,
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};
const AiLabs = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  const navigate = useNavigate();
  // show form or login buttons
  const [showLoginModel, setShowLoginModel] = useState(false);
  // User Details
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  // Login Registration Modal States
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formSubmit = (e) => {
    e.preventDefault();
    setShowLoginModel(true);
    setUserName(e.target.name.value);
    setUserEmail(e.target.email.value);
  };

  function enrollNewUser(e) {
    e.preventDefault();
    handleClose();
    faceio
      .enroll({
        locale: "auto",
        userConsent: false,
        payload: {
          whoami: `{${userName}}`,
          email: `${userEmail}`,
        },
      })
      .then(async () => {
        handleOpen();
        message.success("User Successfully Enrolled! Now you can login.");
        alert("Refresh the page to login.");
        faceio.restartSession();
      })
      .catch(async (errCode) => {
        faceio.restartSession();
        message.error("Registration failed: " + errCode);
      });
  }

  function authenticateUser(e) {
    e.preventDefault();
    handleClose();
    faceio
      .authenticate({
        locale: "auto",
      })
      .then(() => {
        localStorage.setItem("user_phoenixlab", userEmail);
        navigate("/art/aiLabsList");
      })
      .catch((code) => {
        message.error("Authentication failed: " + code);
        faceio.restartSession();
      });
  }
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className=" text-white fs-5" aria-current="page" href="/">
                HOME
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISION
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter"
                  >
                    Crypto Technology Center
                  </a>
                </li>
                <li>
                  <a className="dropdown-item text-dark" href="/web">
                    Web/Mobile Application Suite
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="aiLabs">
        <div className="Header d-flex justify-content-center w-100">
          <h1 className="w-75 text-center m-auto">AI Labs</h1>
        </div>
        <main className="container">
          <div className="row py-4 my-1">
            <div className="text-center">
              <p className="text-justify m-auto fs-5">
                Welcome to our Artificial Intelligence Labs. It is here that we
                research and deploy complex workflows, algorithms, and projects
                that can and will change our future. Our AI Labs Platform
                consists of three different laboratories that were created to
                focus on real-world problems that require real-world solutions.
              </p>
              <a
                className="btn btn-secondary btn-sm mt-2 fw-bold"
                style={{ textDecoration: "none" }}
                href="/dataCollection"
              >
                Learn more
              </a>
            </div>
            <div className="card shadow-lg my-4 p-2 d-flex justify-content-center align-items-center">
              <h2 className="text-center">
                You must login to access all features.
              </h2>
              <button
                className="w-75 btn btn-primary btn-lg"
                onClick={handleOpen}
              >
                Login / Register
              </button>
            </div>
            <div>
              <div id="faceio-modal"></div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  {showLoginModel ? (
                    <div className="h-100 d-flex flex-column justify-content-between ">
                      <div className="text-center card p-1">
                        <h4>Do you already have account?</h4>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={authenticateUser}
                        >
                          Login
                        </button>
                      </div>
                      <div className="text-center card p-1">
                        <h4>New User?</h4>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={enrollNewUser}
                        >
                          Register
                        </button>
                      </div>
                      <div className="d-flex justify-content-around">
                        <button className="btn btn-primary btn-sm">Back</button>
                        <button className="btn btn-primary btn-sm">
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <form
                      onSubmit={formSubmit}
                      className="h-100 d-flex flex-column justify-content-around align-items-center"
                    >
                      <h4>Welcome to Facial Recognition</h4>
                      <div className="w-100">
                        <input
                          className="w-100 p-2 mb-4"
                          type="text"
                          id="name"
                          placeholder="Name"
                          required
                        />
                        <input
                          className="w-100 p-2"
                          type="email"
                          id="email"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <button className="btn btn-primary btn-sm" type="submit">
                        Continue
                      </button>
                    </form>
                  )}
                </Box>
              </Modal>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
export default AiLabs;
