import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const PdfPreview = () => {
    const { id } = useParams();
    const [pdfUrl, setPdfUrl] = useState("");
    const pdfInfo = [
        {
            id: 1,
            topic: "Equity AI",
            name: "Navigating Complex Identities: Unveiling the Significance of Intersectionality in AI Ethics",
            url: "https://docs.google.com/document/d/1yk8xvorzteZJYyx4A7wTSJDTTj6AN7tgJxQG5a_uO-U/preview?usp=sharing",
        },
        {
            id: 2,
            topic: "Equity AI",
            name: "A Blueprint for Equitable Systems: Harnessing AI for Social Justice",
            url: "https://docs.google.com/document/d/1NeIjuRFXTcsbUqaX7Q2Aqz7ebffps8DUzjJYdbDSbLM/preview?usp=sharing",
        },
        {
            id: 3,
            topic: "Equity AI",
            name: "Bridging the Gap: Paving the Way for Gender Equality in AI",
            url: "https://docs.google.com/document/d/1NeIjuRFXTcsbUqaX7Q2Aqz7ebffps8DUzjJYdbDSbLM/edit?preview=sharing",
        },
        {
            id: 4,
            topic: "Equity AI",
            name: "Empowering Tomorrow: The Crucial Role of Education in Ensuring AI Equity",
            url: "https://docs.google.com/document/d/11uvtqcfISACSv7l_koOdhEk5-abip_-rNyG4vcFg8Ig/preview?usp=sharing",
        },
        {
            id: 5,
            topic: "Equity AI",
            name: "Navigating the Workforce Landscape: Ethical Considerations in AI Hiring Algorithms",
            url: "https://docs.google.com/document/d/1l12V25dGgBxaOLW8b3ptph16btLd6DRbG3y4bhKJkjM/preview?usp=sharing",
        },
        {
            id: 6,
            topic: "Equity AI",
            name: "Fostering Inclusivity: Disability-Inclusive AI Beyond Accessibility Compliance",
            url: "https://docs.google.com/document/d/1jve9kxI6sVo6F51o8Uo7YkUVfBXSncQ1dkx_TGsyH9g/preview?usp=sharing",
        },
        {
            id: 7,
            topic: "Equity AI",
            name: "Bridging Gaps in Healthcare: Tackling AI Bias to Address Disparities",
            url: "https://docs.google.com/document/d/1f1VOa67F2TiSvIHSzo4w48AT2vVaZZBBVmxh6DKeQCw/preview?usp=sharing",
        },
        {
            id: 8,
            topic: "Equity AI",
            name: "Embracing Diversity: Cultural Sensitivity in AI Beyond One-Size-Fits-All Solutions",
            url: "https://docs.google.com/document/d/1W3c6-MrqUlAA13ui6XG9UKgvjrFDVxW0-9AW1_G9eRY/preview?usp=sharing",
        },
        {
            id: 9,
            topic: "Equity AI",
            name: "Ensuring Fairness in AI Criminal Justice Applications: A Call for Ethical Implementation",
            url: "https://docs.google.com/document/d/1gSirMWV30IdjVA9-5pNCZjJhf6FkZYAdam8att5-LP8/preview?usp=sharing",
        },
        {
            id: 10,
            topic: "Equity AI",
            name: "Bridging the Divide: The Impact of Socioeconomic Factors on AI Access",
            url: "https://docs.google.com/document/d/1MKl0MMKg-qB8PcRqt1fgOySfEYDhDxIIYERjiLcMsMQ/preview?usp=sharing",
        },
    ];

    useEffect(() => {
        const pdf = pdfInfo.find((pdf) => pdf.id === parseInt(id));
        setPdfUrl(pdf.url);
    }, [id]);
    return (
        <div className="" style={{ height: "100vh", width: "100%" }}>
            <iframe title="Research Paper" src={pdfUrl} frameborder="0" height="100%" width="100%"></iframe>
        </div>
    );
};

export default PdfPreview;
