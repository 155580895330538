import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import "./PressKit.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const PressKit = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5 d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISION <ArrowDropDownIcon className="fs-2" />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter"
                  >
                    Crypto Technology Center
                  </a>
                </li>
                <li>
                  <a className="dropdown-item text-dark" href="/web">
                    Web/Mobile Application Suite
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="Presskit">
        <div className="Header">
          <section>
            <h1 className="Heading">PRESS KIT</h1>
          </section>
        </div>
        <div className="Content">
          <section className="card p-4 shadow-lg m-2">
            <h3> MEDIA RESOURCES FOR JOURNALISTS</h3>
            <p>
              Access a comprehensive press kit with resources designed for
              journalists, providing valuable insights into Phoenix Labs and our
              impact on the world.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>PRESS RELEASES</h3>
            <p>
              Stay informed about our latest announcements through official
              press releases, highlighting key milestones, partnerships, and
              technological breakthroughs.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>MEDIA CONTACTS</h3>
            <p>
              Connect with our media contacts for press inquiries, interviews,
              and media-related engagements.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};
export default PressKit;
