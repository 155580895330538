import React, { useState } from "react";
import axios from "axios";
import "../../styles/Translate.css";

const Translate = () => {
    const [inputText, setInputText] = useState("");
    const [outputText, setOutputText] = useState("");
    const maxChar = 1000;
    const [maxCharWarning, setMaxCharWarning] = useState(false);
    const [inputLanguage, setInputLanguage] = useState("en");
    const [outputLanguage, setOutputLanguage] = useState("hi");
    const handleChange = (e) => {
        setInputText(e.target.value);
        if (e.target.value.length > 1000) {
            setMaxCharWarning(true);
        } else {
            setMaxCharWarning(false);
        }
    };
    const handleDelete = () => {
        setInputText("");
        document.getElementById("inputField").value = "";
    };
    const handleInputLanguage = (e) => {
        setInputLanguage(e.target.value);
    };
    const handleOutputLanguage = (e) => {
        setOutputLanguage(e.target.value);
    };
    const translateText = async () => {
        if (inputLanguage === outputLanguage) {
            setOutputText("Please select different languages");
        } else {
            setOutputText("Translating...");
        }
        const options = {
            method: "GET",
            url: "https://nlp-translation.p.rapidapi.com/v1/translate",
            params: {
                text: inputText,
                to: outputLanguage,
                from: inputLanguage,
            },
            headers: {
                "X-RapidAPI-Key": process.env.REACT_APP_TRANSLATION_API,
                "X-RapidAPI-Host": "nlp-translation.p.rapidapi.com",
            },
        };
        try {
            const response = await axios.request(options);
            setOutputText(response.data.translated_text[outputLanguage]);
        } catch (error) {
            console.error(error);
        }
    };
    const handleCopyText = async () => {
        await navigator.clipboard.writeText(outputText);
    };
    return (
        <div className="Translate d-flex flex-column align-items-center justify-content-center">
            <h1 className="text-secondary my-4">Translate</h1>
            <div className="Layout rounded shadow-lg d-flex justify-content-center align-items-center ">
                <div className="w-50 h-100 leftCard">
                    <div className="text-light p-2 border border-secondary d-flex align-items-center">
                        <span className="me-auto fs-5"> Enter Text Below </span>
                        <select defaultValue="en" className=" py-1 px-3 bg-transparent text-light rounded" onChange={handleInputLanguage}>
                            <optgroup className="bg-dark">
                                <option value="af">Afrikaans</option>
                                <option value="sq">Albanian</option>
                                <option value="am">Amharic</option>
                                <option value="ar">Arabic</option>
                                <option value="hy">Armenian</option>
                                <option value="az">Azerbaijani</option>
                                <option value="ba">Bashkir</option>
                                <option value="eu">Basque</option>
                                <option value="be">Belarusian</option>
                                <option value="bn">Bengali</option>
                                <option value="bs">Bosnian</option>
                                <option value="bg">Bulgarian</option>
                                <option value="my">Burmese</option>
                                <option value="ca">Catalan</option>
                                <option value="ceb">Cebuano</option>
                                <option value="ny">Chichewa</option>
                                <option value="zh-CN">Chinese (Simplified)</option>
                                <option value="zh-TW">Chinese (Traditional)</option>
                                <option value="co">Corsican</option>
                                <option value="hr">Croatian</option>
                                <option value="cs">Czech</option>
                                <option value="da">Danish</option>
                                <option value="nl">Dutch</option>
                                <option value="en">English</option>
                                <option value="eo">Esperanto</option>
                                <option value="et">Estonian</option>
                                <option value="fi">Finnish</option>
                                <option value="fr">French</option>
                                <option value="fy">Frisian</option>
                                <option value="gl">Galician</option>
                                <option value="ka">Georgian</option>
                                <option value="de">German</option>
                                <option value="el">Greek</option>
                                <option value="gu">Gujarati</option>
                                <option value="ht">Haitian Creole</option>
                                <option value="ha">Hausa</option>
                                <option value="haw">Hawaiian</option>
                                <option value="iw">Hebrew</option>
                                <option value="mrj">Hill Mari</option>
                                <option value="hi">Hindi</option>
                                <option value="hmn">Hmong</option>
                                <option value="hu">Hungarian</option>
                                <option value="is">Icelandic</option>
                                <option value="ig">Igbo</option>
                                <option value="id">Indonesian</option>
                                <option value="ga">Irish</option>
                                <option value="it">Italian</option>
                                <option value="ja">Japanese</option>
                                <option value="jw">Javanese</option>
                                <option value="kn">Kannada</option>
                                <option value="kk">Kazakh</option>
                                <option value="km">Khmer</option>
                                <option value="ko">Korean</option>
                                <option value="ku">Kurdish</option>
                                <option value="ky">Kyrgyz</option>
                                <option value="lo">Lao</option>
                                <option value="la">Latin</option>
                                <option value="lv">Latvian</option>
                                <option value="lt">Lithuanian</option>
                                <option value="lb">Luxembourgish</option>
                                <option value="mk">Macedonian</option>
                                <option value="mg">Malagasy</option>
                                <option value="ms">Malay</option>
                                <option value="ml">Malayalam</option>
                                <option value="mt">Maltese</option>
                                <option value="mi">Maori</option>
                                <option value="mr">Marathi</option>
                                <option value="mhr">Mari</option>
                                <option value="mn">Mongolian</option>
                                <option value="ne">Nepali</option>
                                <option value="no">Norwegian</option>
                                <option value="ps">Pashto</option>
                                <option value="pap">Papiamento</option>
                                <option value="fa">Persian</option>
                                <option value="pl">Polish</option>
                                <option value="pt">Portuguese</option>
                                <option value="pa">Punjabi</option>
                                <option value="ro">Romanian</option>
                                <option value="ru">Russian</option>
                                <option value="sm">Samoan</option>
                                <option value="gd">Scots Gaelic</option>
                                <option value="sr">Serbian</option>
                                <option value="st">Sesotho</option>
                                <option value="sn">Shona</option>
                                <option value="sd">Sindhi</option>
                                <option value="si">Sinhala</option>
                                <option value="sk">Slovak</option>
                                <option value="sl">Slovenian</option>
                                <option value="so">Somali</option>
                                <option value="es">Spanish</option>
                                <option value="su">Sundanese</option>
                                <option value="sw">Swahili</option>
                                <option value="sv">Swedish</option>
                                <option value="tl">Tagalog Filipino</option>
                                <option value="tg">Tajik</option>
                                <option value="ta">Tamil</option>
                                <option value="tt">Tatar</option>
                                <option value="te">Telugu</option>
                                <option value="th">Thai</option>
                                <option value="tr">Turkish</option>
                                <option value="udm">Udmurt</option>
                                <option value="uk">Ukrainian</option>
                                <option value="ur">Urdu</option>
                                <option value="uz">Uzbek</option>
                                <option value="vi">Vietnamese</option>
                                <option value="cy">Welsh</option>
                                <option value="xh">Xhosa</option>
                                <option value="yi">Yiddish</option>
                                <option value="yo">Yoruba</option>
                                <option value="zu">Zulu</option>
                            </optgroup>
                        </select>
                    </div>
                    <div>
                        <textarea id="inputField" onChange={handleChange} placeholder="Text..." className="text-light" />
                    </div>
                    <div className="d-flex align-items-center px-3">
                        <div className="text-light me-auto">
                            {maxCharWarning ? <span className="text-danger">Max {maxChar} Characters Allowed</span> : <>{inputText.length} Characters</>}
                        </div>
                        <button onClick={handleDelete} className="btn btn-danger btn-sm me-4 text-light px-4 fw-bold ">
                            Clear All
                        </button>
                        <button className="btn btn-success text-light px-4 fw-bold btn-sm " onClick={translateText}>
                            Translate
                        </button>
                    </div>
                </div>
                <div className="w-50 h-100 rightCard">
                    <div>
                        <div className="text-light d-flex align-items-center p-2 border border-secondary">
                            <span className="fs-5 me-auto"> Output</span>
                            <select defaultValue="hi" className=" py-1 px-3 bg-transparent text-light rounded " onChange={handleOutputLanguage}>
                                <optgroup className="bg-dark">
                                    <option value="af">Afrikaans</option>
                                    <option value="sq">Albanian</option>
                                    <option value="am">Amharic</option>
                                    <option value="ar">Arabic</option>
                                    <option value="hy">Armenian</option>
                                    <option value="az">Azerbaijani</option>
                                    <option value="ba">Bashkir</option>
                                    <option value="eu">Basque</option>
                                    <option value="be">Belarusian</option>
                                    <option value="bn">Bengali</option>
                                    <option value="bs">Bosnian</option>
                                    <option value="bg">Bulgarian</option>
                                    <option value="my">Burmese</option>
                                    <option value="ca">Catalan</option>
                                    <option value="ceb">Cebuano</option>
                                    <option value="ny">Chichewa</option>
                                    <option value="zh-CN">Chinese (Simplified)</option>
                                    <option value="zh-TW">Chinese (Traditional)</option>
                                    <option value="co">Corsican</option>
                                    <option value="hr">Croatian</option>
                                    <option value="cs">Czech</option>
                                    <option value="da">Danish</option>
                                    <option value="nl">Dutch</option>
                                    <option value="en">English</option>
                                    <option value="eo">Esperanto</option>
                                    <option value="et">Estonian</option>
                                    <option value="fi">Finnish</option>
                                    <option value="fr">French</option>
                                    <option value="fy">Frisian</option>
                                    <option value="gl">Galician</option>
                                    <option value="ka">Georgian</option>
                                    <option value="de">German</option>
                                    <option value="el">Greek</option>
                                    <option value="gu">Gujarati</option>
                                    <option value="ht">Haitian Creole</option>
                                    <option value="ha">Hausa</option>
                                    <option value="haw">Hawaiian</option>
                                    <option value="iw">Hebrew</option>
                                    <option value="mrj">Hill Mari</option>
                                    <option value="hi">Hindi</option>
                                    <option value="hmn">Hmong</option>
                                    <option value="hu">Hungarian</option>
                                    <option value="is">Icelandic</option>
                                    <option value="ig">Igbo</option>
                                    <option value="id">Indonesian</option>
                                    <option value="ga">Irish</option>
                                    <option value="it">Italian</option>
                                    <option value="ja">Japanese</option>
                                    <option value="jw">Javanese</option>
                                    <option value="kn">Kannada</option>
                                    <option value="kk">Kazakh</option>
                                    <option value="km">Khmer</option>
                                    <option value="ko">Korean</option>
                                    <option value="ku">Kurdish</option>
                                    <option value="ky">Kyrgyz</option>
                                    <option value="lo">Lao</option>
                                    <option value="la">Latin</option>
                                    <option value="lv">Latvian</option>
                                    <option value="lt">Lithuanian</option>
                                    <option value="lb">Luxembourgish</option>
                                    <option value="mk">Macedonian</option>
                                    <option value="mg">Malagasy</option>
                                    <option value="ms">Malay</option>
                                    <option value="ml">Malayalam</option>
                                    <option value="mt">Maltese</option>
                                    <option value="mi">Maori</option>
                                    <option value="mr">Marathi</option>
                                    <option value="mhr">Mari</option>
                                    <option value="mn">Mongolian</option>
                                    <option value="ne">Nepali</option>
                                    <option value="no">Norwegian</option>
                                    <option value="ps">Pashto</option>
                                    <option value="pap">Papiamento</option>
                                    <option value="fa">Persian</option>
                                    <option value="pl">Polish</option>
                                    <option value="pt">Portuguese</option>
                                    <option value="pa">Punjabi</option>
                                    <option value="ro">Romanian</option>
                                    <option value="ru">Russian</option>
                                    <option value="sm">Samoan</option>
                                    <option value="gd">Scots Gaelic</option>
                                    <option value="sr">Serbian</option>
                                    <option value="st">Sesotho</option>
                                    <option value="sn">Shona</option>
                                    <option value="sd">Sindhi</option>
                                    <option value="si">Sinhala</option>
                                    <option value="sk">Slovak</option>
                                    <option value="sl">Slovenian</option>
                                    <option value="so">Somali</option>
                                    <option value="es">Spanish</option>
                                    <option value="su">Sundanese</option>
                                    <option value="sw">Swahili</option>
                                    <option value="sv">Swedish</option>
                                    <option value="tl">Tagalog Filipino</option>
                                    <option value="tg">Tajik</option>
                                    <option value="ta">Tamil</option>
                                    <option value="tt">Tatar</option>
                                    <option value="te">Telugu</option>
                                    <option value="th">Thai</option>
                                    <option value="tr">Turkish</option>
                                    <option value="udm">Udmurt</option>
                                    <option value="uk">Ukrainian</option>
                                    <option value="ur">Urdu</option>
                                    <option value="uz">Uzbek</option>
                                    <option value="vi">Vietnamese</option>
                                    <option value="cy">Welsh</option>
                                    <option value="xh">Xhosa</option>
                                    <option value="yi">Yiddish</option>
                                    <option value="yo">Yoruba</option>
                                    <option value="zu">Zulu</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div>
                        <textarea id="outputField" value={outputText} disabled className="text-light " />
                    </div>
                    <div className="w-100 text-center">
                        <button onClick={handleCopyText} className="btn btn-secondary btn-sm px-4 fw-bold ">
                            Copy Text
                        </button>
                    </div>
                </div>
            </div>
            <div className="">
                <a href="/dataPreparation" className="btn btn-secondary btn-sm px-5 mt-3 w-100">
                    Back
                </a>
            </div>
        </div>
    );
};

export default Translate;
