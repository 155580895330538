import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import "./CryptoTechnologyCenter.css";
const CryptoTechnologyCenter = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/cryptoTechnologyCenter/events">
                Events & Webinars
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/membership">
                Membership
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white"
                aria-current="page"
                href="/cryptoTechnologyCenter/educationalHub"
              >
                Educational Hub
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Division <ArrowDropDownIcon />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/researchDevelopment"
                  >
                    Research & Development
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps"
                  >
                    DApp's Showcase
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/walletTechnologies"
                  >
                    Wallet Technologies
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dao"
                  >
                    DAO
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptoProducts"
                  >
                    Crypto Products/Services
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/communityForum"
                  >
                    Community Forum
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptotools"
                  >
                    Crypto Currency Tools
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="Crypto">
        <div className="Header">
          <section>
            <h1 className="Heading"> Crypto Technology Center</h1>
          </section>
        </div>
        <div className="Content">
          <section className="card p-4 shadow-lg m-2">
            <h3> Introduction to the Center</h3>
            <p>
              Welcome to the Phoenix Labs Crypto Technology Center, where
              innovation converges with a commitment to addressing real-world
              challenges through cutting-edge solutions. Our center is dedicated
              to harnessing the power of artificial intelligence to create
              transformative technologies that positively impact people's lives.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Commitment to Decentralization</h3>
            <p>
              At Phoenix Labs, we firmly believe in the principles of
              decentralization. Our commitment extends beyond mere ideology; it
              is ingrained in our ethos. We strive to empower individuals
              globally by fostering decentralized systems that promote
              inclusivity, transparency, and resilience. By championing
              decentralization, we pave the way for a more equitable and
              accessible future.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Future-Proof Infrastructure</h3>
            <p>
              In our pursuit of excellence, Phoenix Labs is dedicated to
              building a future-proof infrastructure that can withstand the
              dynamic challenges of the digital era. Our technological
              foundation is robust, scalable, and adaptable, ensuring that we
              remain at the forefront of innovation. Join us on a journey into
              the future, where technology meets foresight.
            </p>
          </section>
        </div>
        {/* <main className="d-flex justify-content-center align-items-center">
                    <section className="m-5">
                        <h1 className="text-center">Global Digital Assets Platform (GDAP)</h1>
                        <div className="">
                            <p className="text-justify">
                                The Global Digital Assets Platform (GDAP) is a blockchain-based network that enables processing, recording, and peer-to-peer
                                (P2P) exchange of digital assets across asset classes. The network provides access to the Noir Coin system, which is leveraged
                                to support the payment arm of Global Digital Asset Platform transactions.
                            </p>
                            <ul>
                                <li>The Global Digital Assets Platform (GDAP) provides a suite of services and benefits to members of our network.</li>
                                <li>
                                    Rapid Market Adaptation members in our network are able to bring digital use cases to life using distributed-ledger
                                    technology (DLT), which saves time, resources, and costs.
                                </li>
                                <li>
                                    Vetted Network- Our members comprise an established network, which processes the equivalent of billions of dollars in
                                    digital assets in on-chain transactions.
                                </li>
                            </ul>
                        </div>
                    </section>
                </main> */}
      </div>
    </>
  );
};

export default CryptoTechnologyCenter;
