import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import "./Futuristicdapps.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const MediChain = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/cryptoTechnologyCenter">
                Home
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/cryptoTechnologyCenter/events">
                Events & Webinars
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white"
                aria-current="page"
                href="/cryptoTechnologyCenter/educationalHub"
              >
                Educational Hub
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Division <ArrowDropDownIcon />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/researchDevelopment"
                  >
                    Research & Development
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps"
                  >
                    DApp's Showcase
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/walletTechnologies"
                  >
                    Wallet Technologies
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dao"
                  >
                    DAO
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptoProducts"
                  >
                    Crypto Products/Services
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/communityForum"
                  >
                    Community Forum
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptotools"
                  >
                    Crypto Currency Tools
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="Futuristicdapps ">
        <div className="Header">
          <section>
            <h1 className="Heading">
              Futuristic Decentralized Applications (DApps) for Unicare® Virtual
              Medical Center
            </h1>
          </section>
        </div>
        <div className="Content">
          <section className="card p-4 shadow-lg m-2">
            <h3>
              {" "}
              MediChain - Decentralized Electronic Health Records (EHR) System
            </h3>
            <ul>
              <li>
                <b>Overview: </b>
                MediChain is a decentralized application that leverages
                blockchain technology to provide a secure and transparent
                Electronic Health Records (EHR) system. It ensures patients have
                control over their medical data while enabling seamless sharing
                with healthcare providers. Smart contracts facilitate access
                permissions, ensuring privacy and compliance.
              </li>
              <li>
                <b>Technological Features:</b>
                <li>
                  Ethereum blockchain for secure and transparent transactions.
                </li>
                <li>
                  Smart contracts to manage access permissions and data sharing.
                </li>
                <li>
                  IPFS (InterPlanetary File System) for decentralized storage of
                  medical records.
                </li>
              </li>
              <li>
                <b>Social Responsibility:</b>
                <li>
                  Empowers patients to have control over their health data.
                </li>
                <li>
                  Facilitates interoperability between healthcare providers.
                </li>
              </li>
              <li>
                Enhances data security and reduces the risk of data breaches.
              </li>
            </ul>
            {/* <a href="/cryptoTechnologyCenter/Dapps/Caretoken">
              <button className="FuturisticButton">Learn More</button>
            </a> */}
          </section>
        </div>
      </div>
    </>
  );
};

export default MediChain;
