import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/ModelEvaluation.css";
const ModelEvaluation = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="#edgecase">
                Edge Case Testing
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="#real-world">
                Real World
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="#benchmarking">
                Benchmarking
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="ModelEvaluation">
        <div className="Header d-flex align-items-center w-100">
          <h1 className="w-75 text-center m-auto">Model Evaluation</h1>
        </div>

        <main className="p-4">
          <div id="edgecase" className="m-4 p-3">
            <h1>Edge Case Testing</h1>
            <p>
              Welcome to our Edge Case Testing page! At our groundbreaking
              platform, we specialize in providing real-world model performance
              validation and verification across a range of use cases, and edge
              case testing is a critical component of our comprehensive
              approach. What are edge cases? They are the scenarios where
              artificial intelligence (AI) models often struggle to perform
              accurately, leading to potential errors or unexpected behavior.
              Our platform allows you to push the boundaries of your AI models
              by subjecting them to these challenging edge cases.
              <span className="d-block mt-2"></span>
              Through our dynamic elements and components, we create realistic,
              real-world environments that accurately simulate the deployment
              scenarios your AI models will face. We understand that the
              performance of AI models in edge cases can have significant
              real-world implications, and our platform is designed to help you
              identify and address any shortcomings.
              <span className="d-block mt-2"></span>
              With our state-of-the-art benchmarking capabilities, we compare
              your model's performance against other industry models, providing
              you with valuable insights and ensuring superior results. By
              subjecting your AI models to rigorous edge case testing, you can
              improve their robustness, reliability, and overall performance.
              <span className="d-block mt-2"></span>
              Trust our platform to uncover hidden vulnerabilities, enhance the
              accuracy of your AI models, and ensure they perform flawlessly
              even in the most challenging situations. Contact us today to
              experience the power of edge case testing and elevate your AI
              models to new heights.
            </p>
          </div>
          <div id="real-world" className="m-4 p-3">
            <h1>Real-World Simulation</h1>
            <p>
              Welcome to our Real-World Simulation page! At our groundbreaking
              platform, we understand that testing AI models in realistic
              environments is crucial for ensuring their performance in the real
              world. That's why we offer cutting-edge capabilities to simulate
              real-world deployment environments, enabling you to validate and
              verify your models effectively.
              <span className="d-block mt-2"></span>
              Our platform provides dynamic elements and components that
              replicate the complexities and nuances of real-world scenarios. By
              creating accurate simulations, we enable you to evaluate how your
              AI models will behave and perform in various practical situations.
              <span className="d-block mt-2"></span>
              Whether it's autonomous vehicles navigating busy city streets,
              chatbots interacting with users in dynamic conversations, or
              predictive models making critical decisions based on real-time
              data, our real-world simulations empower you to test your models
              comprehensively.
              <span className="d-block mt-2"></span>
              With our platform, you can confidently assess the strengths and
              weaknesses of your AI models, identify potential areas for
              improvement, and refine their performance. By leveraging realistic
              simulations, you can optimize your models' responsiveness,
              accuracy, and adaptability, ensuring they deliver exceptional
              results in the real world.
              <span className="d-block mt-2"></span>
              Partner with us to access state-of-the-art tools and expertise in
              real-world simulation. Take advantage of our groundbreaking
              platform to validate your AI models, mitigate risks, and unleash
              their full potential. Experience the future of AI testing today!
              <span className="d-block mt-2"></span>
            </p>
          </div>
          <div id="benchmarking" className="m-4 p-3">
            <h1>Benchmarking</h1>
            <p>
              Welcome to our Benchmarking page! At our pioneering platform, we
              believe that assessing the performance of AI models against
              industry benchmarks is essential for driving innovation and
              ensuring superior results. Our state-of-the-art benchmarking
              capabilities allow you to evaluate your models' performance and
              compare them against the best in the industry.
              <span className="d-block mt-2"></span>
              Why benchmarking? By benchmarking your AI models, you gain
              valuable insights into their strengths, weaknesses, and areas for
              improvement. It provides a quantitative and qualitative assessment
              of your models' performance, enabling you to make informed
              decisions and optimize their capabilities.
              <span className="d-block mt-2"></span>
              Our platform leverages cutting-edge benchmarks and evaluation
              metrics to objectively measure and compare the performance of your
              AI models. We utilize extensive datasets, industry standards, and
              rigorous evaluation methodologies to provide accurate and
              meaningful benchmarking results.
              <span className="d-block mt-2"></span>
              With our benchmarking capabilities, you can validate the
              effectiveness of your models, identify areas where they excel, and
              pinpoint opportunities for enhancement. By understanding how your
              models stack up against industry leaders, you can fine-tune their
              performance, stay ahead of the competition, and drive innovation
              in your field.
              <span className="d-block mt-2"></span>
              Trust our groundbreaking platform to deliver comprehensive
              benchmarking insights that will empower you to optimize your AI
              models' performance. Partner with us and join a community
              committed to excellence, continuous improvement, and pushing the
              boundaries of AI. Get started on your benchmarking journey today!
            </p>
          </div>
          <div className="m-4 d-flex justify-content-evenly">
            <a
              className="btn btn-primary btn-lg"
              style={{ color: "aliceblue", textDecoration: "none" }}
              href="/art"
            >
              Back
            </a>
            <a
              className="btn btn-primary btn-lg"
              style={{ color: "aliceblue", textDecoration: "none" }}
              href="/dataCollection"
            >
              Learn more
            </a>
          </div>
        </main>
      </div>
    </>
  );
};

export default ModelEvaluation;
