import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const Career = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white fs-5" href="/vision">
                VISION
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5 d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                DIVISION <ArrowDropDownIcon className="fs-2" />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item text-dark" href="/art">
                    Artificial Intelligence Center
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter"
                  >
                    Crypto Technology Center
                  </a>
                </li>
                <li>
                  <a className="dropdown-item text-dark" href="/web">
                    Web/Mobile Application Suite
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white fs-5" href="/projects">
                PROJECTS
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="Career">
        <div className="Header">
          <section>
            <h1 className="text-center text-2xl font-semibold mt-10">
              CAREERS
            </h1>
          </section>
        </div>
        <div className="Content">
          <section className="card p-4 shadow-lg m-2">
            <h3>JOB OPPORTUNITIES WITHIN PHOENIX LABS</h3>
            <p>
              Explore exciting career opportunities at Phoenix Labs. Join a
              dynamic team of innovators committed to shaping the future through
              technology.
            </p>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3 className="text-center">Phoenix Labs Internship Program</h3>
            <p>
              Welcome to the Phoenix Labs Internship Program - an unparalleled
              opportunity to embark on a journey of innovation, learning, and
              professional growth. Our world-class internship program is
              designed to provide aspiring individuals with hands-on experience,
              mentorship, and exposure to cutting-edge technologies. At Phoenix
              Labs, we believe in nurturing the next generation of talent,
              fostering creativity, and making a positive impact on the world
              through groundbreaking solutions.
            </p>
            <b className="fs-4">Why Intern with Phoenix Labs?</b>
            <p>
              Phoenix Labs is a global technology company committed to creating
              innovative solutions to address real-world challenges such as the
              poverty/wealth gap, disease, and equity. Interning with us means
              being part of a dynamic and diverse team dedicated to leveraging
              the power of artificial intelligence to make a positive impact on
              people{"'"}s lives.
            </p>
            <b className="fs-4">Program Duration</b>
            <p>
              Our internship program typically runs for [X] months, providing
              interns with a comprehensive and immersive experience in their
              chosen field. The duration may vary based on the specific
              internship position.
            </p>
            <b className="fs-4"> Key Features</b>
            <ol type="1">
              <li>
                <b>Hands-On Experience:</b> Engage in real-world projects that
                contribute to our mission of creating innovative solutions. Work
                alongside industry experts and gain practical insights into the
                latest technologies.
              </li>
              <li>
                <b>Mentorship:</b> Benefit from mentorship by seasoned
                professionals who are passionate about guiding and supporting
                your growth. Receive personalized feedback and guidance to
                enhance your skills.
              </li>
              <li>
                <b>Professional Development:</b> Participate in workshops,
                training sessions, and networking events tailored to enhance
                your professional and personal development. Acquire skills that
                go beyond the classroom.
              </li>
              <li>
                <b>Innovation and Collaboration:</b> Immerse yourself in a
                culture of innovation and collaboration. Contribute your ideas,
                explore new concepts, and collaborate with diverse teams to
                solve complex problems.
              </li>
              <li>
                <b> Networking Opportunities: </b>Connect with professionals,
                industry leaders, and fellow interns through networking events,
                seminars, and social activities. Build lasting relationships
                that extend beyond the internship.
              </li>
              <li>
                <b>Exposure to AI and Cutting-Edge Technologies: </b>Experience
                the intersection of artificial intelligence and real-world
                problem-solving. Contribute to projects that push the boundaries
                of technology and have a meaningful impact on society.
              </li>
              <li>
                <b>Community Involvement: </b> Engage in community initiatives
                and social responsibility projects aligned with Phoenix Labs
                {"'"}
                commitment to addressing global challenges.
              </li>
            </ol>
            <b className="fs-4">How to Apply</b>
            <p>
              To be part of the Phoenix Labs Internship Program, follow these
              steps:
            </p>
            <ol type="1">
              <li>
                <b>Explore Opportunities: </b>Visit our Careers page to view
                available internship positions and their respective
                requirements.
              </li>
              <li>
                <b>Application Submission: </b>Submit your application through
                our online portal, including a resume, cover letter, and any
                additional application materials requested for the specific
                position.
              </li>
              <li>
                <b>Interview Process: </b>Shortlisted candidates will undergo a
                thorough interview process, which may include technical
                assessments and behavioral interviews.
              </li>
              <li>
                <b>Selection:</b> Successful candidates will be notified of
                their selection and provided with details regarding the
                internship program.
              </li>
            </ol>
            <b className="fs-4">Eligibility</b>
            <p>
              Our internship program is open to passionate and talented
              individuals from diverse backgrounds. While specific requirements
              may vary by position, we generally look for candidates who:
            </p>
            <ul>
              <li>Are pursuing a degree in a relevant field.</li>
              <li>Demonstrate strong analytical and problem-solving skills.</li>
              <li>Possess effective communication and teamwork abilities.</li>
              <li>Exhibit a genuine interest in technology and innovation.</li>
            </ul>
            <b className="text-center fs-3">Join the Phoenix Labs </b>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Internship Community</h3>
            <p>
              By joining the Phoenix Labs Internship Program, you become part of
              a community that values curiosity, collaboration, and continuous
              learning. We are committed to providing an enriching experience
              that propels your career forward and prepares you for the
              challenges of tomorrow. <br />
            </p>
            <span className="text-center fs-4">
              <b>
                Apply today and embark on a journey of innovation with Phoenix
                Labs!
              </b>
            </span>
          </section>
          {/* Warning Section */}
          <section className="card p-4 shadow-lg m-2 bg-yellow-100 border-l-4 border-yellow-500 ">
            <h3 className="text-center text-red-700 font-bold text-xl">
              ⚠️ URGENT FRAUD ALERT {"-"} PHOENIX LABS GLOBAL DOES NOT CHARGE
              FEES FOR EMPLOYMENT OR TRAINING
            </h3>
            <p className="text-red-600">
              Phoenix Labs Global has recently become aware of individuals
              posing as company employees or representatives who are attempting
              to exploit new hires, recruits, and interns by requesting payments
              for training or other hiring-related purposes.{" "}
              <b>THIS IS A SCAM</b> and these individuals are in no way
              affiliated with Phoenix Labs Global.
            </p>
            <p>
              We want to emphasize the following:
              <ul className="list-disc ml-6">
                <li>
                  Phoenix Labs Global <b>DOES NOT</b> charge any fees for
                  training, onboarding, or hiring. Any request for payment in
                  exchange for employment, training, or any related service is
                  fraudulent.
                </li>
                <li>
                  All official communications from Phoenix Labs Global will come
                  from official company email addresses ending in{" "}
                  <b>@jmediacorp.com</b> or <b>@phoenixlabsglobal.com</b> and
                  never from third-party domains or personal email accounts.
                </li>
                <li>
                  <b>DO NOT</b> share personal, financial, or any sensitive
                  information with anyone claiming to represent Phoenix Labs
                  Global who requests money in any form.
                </li>
              </ul>
            </p>
            <p>
              If you have been contacted by someone claiming to be from Phoenix
              Labs Global and they have requested payment or personal
              information, please report this immediately by emailing{" "}
              <a href="mailto:aeshort@jmediacorp.com" className="text-blue-500">
                aeshort@jmediacorp.com
              </a>{" "}
              or{" "}
              <a
                href="mailto:radingwu@jmediacorp.com"
                className="text-blue-500"
              >
                radingwu@jmediacorp.com
              </a>
              .
            </p>
            <p className="text-center font-semibold text-red-600">
              Your safety and security are our top priorities. We are committed
              to maintaining the highest ethical standards and ensuring a safe,
              transparent hiring process for all applicants.
            </p>
            <p className="text-center text-red-600 font-bold">
              DO NOT FALL VICTIM TO THESE SCAMS. BE VIGILANT.
            </p>
            <p className="text-center italic text-gray-700">
              Sincerely,
              <br />
              Phoenix Labs Global Human Resources
              <br />
              Protecting Innovation, Protecting You.
            </p>
          </section>
        </div>
      </div>
    </>
  );
};

export default Career;
