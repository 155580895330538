import { useState, useEffect, useRef } from "react";
import { Annotorious } from "@recogito/annotorious";
import "@recogito/annotorious/dist/annotorious.min.css";
import "../../styles/Annotate.css";
import LinearProgress from "@mui/material/LinearProgress";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DownloadIcon from "@mui/icons-material/Download";
const Annotate = () => {
    const [selectedImage, setSelectedImage] = useState([{ name: null }]);
    const [selectedImageUrl, setSelectedImageUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [annotations, setAnnotations] = useState([]);
    const imageRef = useRef(null);
    const handleImageUpload = async (e) => {
        setIsLoading(true);
        setIsFileUploaded(false);
        const tempFile = [];
        const file = e.target.files[0];
        await tempFile.push(file);
        setSelectedImage(tempFile);
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            localStorage.removeItem("annotations");
            setSelectedImageUrl(imageUrl);
            setAnnotations([]);
        }
        setIsLoading(false);
        setIsFileUploaded(true);
    };
    useEffect(() => {
        if (selectedImageUrl) {
            initAnnotorious();
        }
    }, [selectedImageUrl]);

    useEffect(() => {
        loadAnnotationsFromStorage();
    }, []);

    const initAnnotorious = () => {
        const image = imageRef.current;
        const annotorious = new Annotorious({ image });
        annotorious.clearAnnotations();

        const savedAnnotations = JSON.parse(localStorage.getItem("annotations")) || [];

        savedAnnotations.forEach((annotation) => {
            annotorious.addAnnotation(annotation);
        });

        annotorious.on("createAnnotation", (annotation) => {
            const updatedAnnotations = [...annotations, annotation];
            setAnnotations(updatedAnnotations);
            saveAnnotationsToStorage(updatedAnnotations);
        });
        annotorious.setDrawingTool("polygon");

        return () => annotorious.destroy();
    };

    const saveAnnotationsToStorage = (annotations) => {
        const saveAnnotations = JSON.parse(localStorage.getItem("annotations")) || [];
        const updatedAnnotations = [...saveAnnotations, ...annotations];
        localStorage.setItem("annotations", JSON.stringify(updatedAnnotations));
    };

    const loadAnnotationsFromStorage = () => {
        const savedAnnotations = JSON.parse(localStorage.getItem("annotations")) || [];
        setAnnotations(savedAnnotations);
    };

    const downloadAnnotations = () => {
        const allAnnotations = JSON.parse(localStorage.getItem("annotations")) || [];
        const annotationsBlob = new Blob([JSON.stringify(allAnnotations)], {
            type: "application/json",
        });
        const url = URL.createObjectURL(annotationsBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "annotations.json";
        a.click();
    };
    //
    const [orientation, setOrientation] = useState(null);

    const determineOrientation = (e) => {
        const img = e.target;
        if (img.naturalWidth >= img.naturalHeight) {
            setOrientation("landscape");
        } else {
            setOrientation("portrait");
        }
    };
    const [showImageUpload, setShowImageUpload] = useState(true);
    const handleReset = () => {
        localStorage.removeItem("annotations");
        window.location.reload();
    };
    return (
        <>
            <div className="Annotate d-flex flex-column align-items-center justify-content-center ">
                <div className={`Layout h-100 w-100 ${showImageUpload ? "d-flex" : "d-none"} align-items-center justify-content-center`}>
                    <div className="UpperCard rounded d-flex justify-content-center align-items-center">
                        <div className="FormCard text-center d-flex flex-column justify-content-evenly align-items-center">
                            <h1>Annotate</h1>
                            <p>Upload Image files using below button to get started</p>
                            <input type="file" accept="image/*" name="file" id="file" className="d-none" onChange={handleImageUpload} />
                            <label htmlFor="file" className="btn btn-primary w-100">
                                <i className="bi bi-upload"></i> Upload Image
                            </label>
                            {isLoading && <LinearProgress style={{ width: "100%" }} />}
                            {selectedImage[0].name === null ? (
                                <div>No File Selected</div>
                            ) : (
                                <div className="mb-3 ImagePreview">
                                    <span>{selectedImage[0].name} File Selected</span>
                                    <div className="ImageDiv border border-1">
                                        <img src={selectedImageUrl} alt="ImagePreview" />
                                    </div>
                                </div>
                            )}

                            {isFileUploaded && (
                                <button className="btn btn-primary btn-sm bx-5" onClick={() => setShowImageUpload(false)}>
                                    Get Started
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`ImageEditor ${showImageUpload ? "d-none" : "d-block"}`}>
                    <div className="filters d-flex align-items-center justify-content-start">
                        <button className="border border-0 h-100 px-4">
                            <RestartAltIcon onClick={handleReset} />
                        </button>
                        <button className="border border-0 h-100 px-4" onClick={downloadAnnotations}>
                            <DownloadIcon />
                        </button>
                    </div>
                    <div className="ImageSide d-flex justify-content-center align-items-center">
                        <img ref={imageRef} src={selectedImageUrl} alt="AnnotationImage" className={orientation} onLoad={determineOrientation} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Annotate;
