import React, { useEffect, useRef } from "react";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logo from "../../images/logo.png";
import "../../../styles/Navbar.css";
import "./CryptoTools.css";
const CryptoTechnologyCenter = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex  align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-around align-items-center w-100">
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/cryptoTechnologyCenter/events">
                Events & Webinars
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a className="text-white" href="/membership">
                Membership
              </a>
            </li>
            <li className="nav-item px-5 py-2 p-md-0 ">
              <a
                className=" text-white"
                aria-current="page"
                href="/cryptoTechnologyCenter/educationalHub"
              >
                Educational Hub
              </a>
            </li>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white d-flex align-items-center"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Division <ArrowDropDownIcon />
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/researchDevelopment"
                  >
                    Research & Development
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dapps"
                  >
                    DApp's Showcase
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/walletTechnologies"
                  >
                    Wallet Technologies
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/Dao"
                  >
                    DAO
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/cryptoProducts"
                  >
                    Crypto Products/Services
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/cryptoTechnologyCenter/communityForum"
                  >
                    Community Forum
                  </a>
                </li>
              </ul>
            </div>
            <li className="nav-item px-5 py-2 p-md-0">
              <a className="text-white" href="/aboutus">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="Cryptotools">
        <div className="Header">
          <section>
            <h1 className="Heading"> Cryptocurrency Tools</h1>
          </section>
        </div>
        <div className="Content">
          <section className="card p-4 shadow-lg m-2">
            <h3> NFT Generator:</h3>
            <ul>
              <li>
                <b>OpenSea Clone:</b> An open-source NFT generator that allows
                users to create, buy, and sell NFTs.
              </li>
              <li>
                <b>Rarible Protocol:</b> An open-source protocol for creating
                and managing NFTs on the Ethereum blockchain.
              </li>
              <li>
                <b> IPFS Integration:</b> Include IPFS (InterPlanetary File
                System) for decentralized storage of NFT metadata.
              </li>
            </ul>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Smart Contract Creator:</h3>
            <ul>
              <li>
                <b>Solidity: </b>An open-source, high-level programming language
                for writing smart contracts on the Ethereum blockchain.
              </li>
              <li>
                <b>Remix IDE:</b> An open-source web and desktop application
                that helps users write, deploy, and debug smart contracts.
              </li>
              <li>
                <b>Truffle Suite:</b> An open-source development environment,
                testing framework, and asset pipeline for Ethereum.
              </li>
            </ul>
          </section>
          <section className="card p-4 shadow-lg m-2">
            <h3>Additional Cryptocurrency Tools:</h3>
            <ul>
              <li>
                <b>Decentralized Finance (DeFi) Tools:</b>
              </li>
              <ul>
                <li>
                  <i> Uniswap:</i> An open-source decentralized exchange
                  protocol for trading ERC-20 tokens.
                </li>
                <li>
                  <i>Compound: </i>An open-source protocol for algorithmic,
                  efficient money markets on the Ethereum blockchain.
                </li>
              </ul>

              <li>
                <b>Wallet Integration:</b>
              </li>
              <ul>
                <li>
                  <i> MetaMask:</i> A popular open-source wallet for managing
                  your Ethereum-based assets and interacting with decentralized
                  applications ({" "}
                  <a href="http/cryptoTechnologyCenter/Dapps">DApps</a> ).
                </li>
                <li>
                  <i> WalletConnect:</i> An open-source protocol for connecting
                  decentralized applications to mobile wallets.
                </li>
              </ul>

              <li>
                <b>Oracles:</b>
              </li>
              <ul>
                <li>
                  <i>Chainlink:</i> An open-source decentralized oracle network
                  that enables smart contracts on Ethereum to securely connect
                  to external data sources.
                </li>
              </ul>
              <li>
                {" "}
                <b>Privacy and Scalability: </b>
              </li>
              <ul>
                <li>
                  <i> zkSync:</i> An open-source layer 2 scaling solution for
                  Ethereum that uses zkRollups for increased throughput and
                  privacy.
                </li>
              </ul>
              <li>
                <b> Cross-Chain Integration:</b>
              </li>
              <ul>
                <li>
                  <i> Polkadot:</i> An open-source multi-chain platform that
                  facilitates interoperability between different blockchains.
                </li>
                <li>
                  <i>Cosmos SDK:</i>
                  An open-source framework for building interoperable, scalable,
                  and secure blockchain applications.
                </li>
              </ul>
            </ul>
          </section>
        </div>
        {/* <main className="d-flex justify-content-center align-items-center">
                    <section className="m-5">
                        <h1 className="text-center">Global Digital Assets Platform (GDAP)</h1>
                        <div className="">
                            <p className="text-justify">
                                The Global Digital Assets Platform (GDAP) is a blockchain-based network that enables processing, recording, and peer-to-peer
                                (P2P) exchange of digital assets across asset classes. The network provides access to the Noir Coin system, which is leveraged
                                to support the payment arm of Global Digital Asset Platform transactions.
                            </p>
                            <ul>
                                <li>The Global Digital Assets Platform (GDAP) provides a suite of services and benefits to members of our network.</li>
                                <li>
                                    Rapid Market Adaptation members in our network are able to bring digital use cases to life using distributed-ledger
                                    technology (DLT), which saves time, resources, and costs.
                                </li>
                                <li>
                                    Vetted Network- Our members comprise an established network, which processes the equivalent of billions of dollars in
                                    digital assets in on-chain transactions.
                                </li>
                            </ul>
                        </div>
                    </section>
                </main> */}
      </div>
    </>
  );
};

export default CryptoTechnologyCenter;
