import React, { useEffect, useRef } from "react";
// import ChatBot from "react-simple-chatbot";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
// import { ThemeProvider } from "styled-components";
import Logo from "../images/logo.png";
import "../../styles/Navbar.css";
import "../../styles/LearningLab.css";
const LearningLab = () => {
  const navbarRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const navbarHeight = navbarRef.current.offsetHeight;
    contentRef.current.style.marginTop = navbarHeight + "px";
  }, []);
  // Chatbot Code
  // const theme = {
  //     background: "#f5f8fb",
  //     fontFamily: "Helvetica Neue",
  //     headerBgColor: "#EF6C00",
  //     headerFontColor: "#fff",
  //     headerFontSize: "15px",
  //     botBubbleColor: "#EF6C00",
  //     botFontColor: "#fff",
  //     userBubbleColor: "#fff",
  //     userFontColor: "#4a4a4a",
  // };
  const steps = [
    {
      id: "1",
      message: "What is your name?",
      trigger: "2",
    },
    {
      id: "2",
      user: true,
      trigger: "3",
    },
    {
      id: "3",
      message: "Hi {previousValue}, Welcome to Learning Lab!",
      trigger: "4",
    },
    {
      id: "4",
      message: "Would You like to learn something new?",
      trigger: "5",
    },
    {
      id: "5",
      user: true,
      trigger: "6",
    },
    {
      id: "6",
      message: "Sorry, We are in development phase. We will be back soon!",
      trigger: "7",
    },
    {
      id: "7",
      message: "Thank You for using me!",
      end: true,
    },
  ];
  return (
    <>
      <nav
        ref={navbarRef}
        className="navbar navbar-expand-lg fixed-top bg-secondary"
      >
        <div className="w-100 d-flex">
          <div className="w-50 d-flex align-items-center justify-content-start">
            <img
              src={Logo}
              className="Logo d-none d-sm-block"
              alt="Logo of Company"
            />
            <a className="navbar-brand text-white ms-0 ms-sm-4 fs-4" href="/">
              Phoenix Labs
            </a>
          </div>
          <div className="w-50 d-flex align-items-center justify-content-end">
            <button
              className="navbar-toggler "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-white">
                <ViewHeadlineIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="navbar-collapse w-100" id="navbarSupportedContent">
          <ul className="navbar-nav d-flex justify-content-end align-items-center gap-5 w-100 me-0 me-lg-5 pe-0 pe-lg-5">
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Phoenix Academy
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/art/aiLabsList/learningLab"
                  >
                    Workshops
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/art/aiLabsList/learningLab"
                  >
                    Mentorship
                  </a>
                </li>
              </ul>
            </div>
            <div className="dropdown px-5 py-2 p-md-0 ">
              <a
                className="dropdown text-white fs-5"
                href="/"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Clubs
              </a>
              <ul
                className="dropdown-menu mt-md-4"
                aria-labelledby="dropdownMenuLink"
              >
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/art/aiLabsList/learningLab"
                  >
                    Music Makers
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/art/aiLabsList/learningLab"
                  >
                    Chess Club
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item text-dark"
                    href="/art/aiLabsList/learningLab"
                  >
                    Noir Blocks
                  </a>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </nav>

      <div ref={contentRef} className="learningLab">
        <div className="header">
          <section className=" w-100 d-flex align-items-center">
            <h1 className="w-75 m-auto text-center ">Learning Lab</h1>
          </section>
        </div>
        <div className="m-4 d-flex justify-content-center">
          <button type="button" className="btn btn-primary btn-lg">
            <a
              style={{ color: "aliceblue", textDecoration: "none" }}
              href="/learningLab"
            >
              Get Started Today
            </a>
          </button>
        </div>
        <div className="card shadow m-5 p-4">
          <p>
            <b> Home of Phoenix Academy</b>
            <br />
            Welcome to Learning Labs, the beating heart of Phoenix Academy.
            Here, we nurture minds and cultivate excellence in the field of
            artificial intelligence. Through cutting-edge courses, workshops,
            and mentorship programs, we empower individuals to become the next
            generation of AI pioneers.
            <br />
            <br />
            Our esteemed faculty, composed of leading experts in AI, ensures
            that students receive a comprehensive and hands-on education. From
            foundational concepts to advanced applications, Learning Labs
            provides a nurturing environment for aspiring AI enthusiasts to
            flourish and bring their boldest ideas to life.
          </p>
        </div>
      </div>
      <div>
        {/* <ThemeProvider theme={theme}> */}
        {/* <ChatBot steps={steps} floating={true} />  */}
        {/* </ThemeProvider> */}
      </div>
    </>
  );
};

export default LearningLab;
