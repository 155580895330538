import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import play from '../images/play.png'
import stop from '../images/stop.png'

const VoiceRecognition = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const mediaRecorderRef = React.useRef(null);
  const audioChunksRef = React.useRef([]);
  const [count, setCount] = useState(1);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream);
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error(error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      setCount(count+1);
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setMediaStream(null);
      setTimeout(() => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const fileName = `recording-${count}.wav`;
        saveAs(audioBlob, fileName);
        audioChunksRef.current = [];
      }, 1000);
    }
  };

  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [mediaStream]);

  return (
    <div  >
      <button onClick={startRecording} disabled={isRecording} style={{ margin: "10px" , backgroundColor:"white" , border: "2px solid black" , padding: "0 10px" }}>
        <img src={play} alt="" width="24"/>start  
      </button>
      <button onClick={stopRecording} disabled={!isRecording} style={{ margin: "10px" , backgroundColor:"white" , border: "2px solid black" , padding: "0 10px"}}>
        <img src={stop} alt="" width="24"/>stop
      </button>
    </div>
  );
};

export default VoiceRecognition;